@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkboxDiv {
    margin-right: 10px;
}

[dir='rtl'] {
    .checkboxDiv {
        margin-right: 0;
        margin-left: 10px;
    }
}
