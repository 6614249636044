@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.groupTitle {
    font-size: 16px;
    color: $ff-color-blue-cinema;
    text-transform: none;
    font-weight: normal;
    font-style: normal;
    margin: map-get($ff-spacing-sizes, '8') 0;
}

.downloadOption {
    margin-right: map-get($ff-spacing-sizes, '16');
}

.downloadTitle {
    font-size: 13px;
    color: $ff-color-blue-cinema;
    margin-bottom: map-get($ff-spacing-sizes, '4');
    word-break: break-word;
    line-height: $ff-line-height-120;
    @include line-overflow(3);
}

.downloadSize {
    font-size: 10px;
    color: $ff-color-grey-slate;
    margin-top: 5px;
}

.divider {
    border: none;
    border-top: 1px solid $ff-color-grey-snow;
    margin-left: -#{map-get($ff-spacing-sizes, '16')};
    margin-right: -#{map-get($ff-spacing-sizes, '16')};
    margin-top: map-get($ff-spacing-sizes, '16');
    margin-bottom: map-get($ff-spacing-sizes, '16');
}

.viewButton {
    display: flex;
    color: $ff-color-blue-lighter-dark;
    gap: $ff-spacing-1;
    align-items: center;
    cursor: pointer;
    padding: $ff-spacing-1;
    border-radius: $ff-border-radius-circle;

    &:hover {
        background-color: $ff-color-grey-solitude;
    }

    .viewIcon {
        height: 20px;
        width: 20px;

        path {
            fill: $ff-color-blue-lighter-dark;
        }
    }
}
