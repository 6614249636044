@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.termsAndConditions {
    width: 100%;
    font-size: #{map-get($ff-font-sizes-array, '12')}rem;
    color: $ff-color-grey-slate;
    font-weight: $ff-font-weight-semibold;
    line-height: 100%;
    vertical-align: middle;

    .downloadPromptLink {
        font-size: #{map-get($ff-font-sizes-array, '12')}rem;
    }

    span {
        vertical-align: middle;
    }
}
