@import 'theoplayer/ui.css';

@font-face {
    font-family: 'FFVideoFont';
    src: url('./../assets/fonts/video-js-fonts/FFVideoFont.eot?u9qq41');
    src: url('./../assets/fonts/video-js-fonts/FFVideoFont.eot?u9qq41#iefix') format('embedded-opentype'),
        url('./../assets/fonts/video-js-fonts/FFVideoFont.ttf?u9qq41') format('truetype'),
        url('./../assets/fonts/video-js-fonts/FFVideoFont.woff?u9qq41') format('woff'),
        url('./../assets/fonts/video-js-fonts/FFVideoFont.svg?u9qq41#FFVideoFont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'FFVideoFont' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$button-height: 48px;
$button-width: 45px;
$progress-bar-radius: 500px;
$progress-bar-size: 10px;
$time-indicator-size: 15px;
$controls-padding-top: $progress-bar-size + $time-indicator-size;
$controls-active-color: $ff-color-blue-light;
$controls-inactive-color: $ff-color-white;
$controls-font: 'FFVideoFont';
$controls-size: 32px;
$controls-margin: 5%;
$controls-width: 100% - $controls-margin * 2;

.theo-context-menu {
    display: none;
}

.theo-secondary-color {
    color: $controls-inactive-color !important;
}
.theo-menu-animation-container {
    border-radius: $ff-border-radius-sm;
}

.theoplayer-skin .theo-menu {
    bottom: 60px;
}
.theo-button-tooltip {
    display: none !important;
}

.vjs-menu-content {
    .theo-settings-control-menu-quality-automatic {
        color: rgba($ff-color-white, 0.5) !important;
    }
    > li:first-of-type {
        border-top: rgba($ff-color-white, 0.5) 1px !important;
        border-top-style: solid !important;
    }
    > li {
        border-top: rgba($ff-color-white, 0.2) 1px !important;
        border-top-style: solid !important;
        > span {
            color: $controls-inactive-color !important;
        }
    }
}

[class*='theoplayer-skin'] {
    [class*='theo-submenu'] {
        [class*='theo-menu-header'] {
            background-image: url("data:image/svg+xml,%3Csvg fill='%23FFFFFF' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z'/%3E%3C/svg%3E");
        }
    }
}

.theo-primary-color,
.vjs-selected {
    color: $controls-active-color !important;
}

.theo-primary-background {
    color: $controls-inactive-color !important;
    background-color: rgba(0, 15, 44, 0) !important;
}

.theo-tertiary-background {
    color: $controls-inactive-color !important;
    background-color: rgba(0, 15, 44, 0.8) !important;
}

.theo-settings-control-label-hd {
    background-color: $controls-active-color !important;
}

.vjs-big-play-button {
    display: none !important;
}

.theoplayer-skin,
.theoplayer-skin:hover {
    .vjs-big-play-button,
    .vjs-big-play-button:focus,
    .vjs-big-play-button:hover {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background: url('./../../icons/video-icons/BigPlayButton.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-color: none !important;
        height: 90px;
        width: 90px;
        padding: 0px;

        .theo-big-play-button-svg-container > svg {
            display: none;
        }
    }
}

.vjs-big-play-button,
.vjs-big-play-button:focus,
.vjs-big-play-button:hover {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: none;
    background: url('./../../icons/video-icons/BigPlayButton.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: none !important;
    height: 90px;
    width: 90px;
    padding: 0px;

    .theo-big-play-button-svg-container > svg {
        display: none;
    }
}

.vjs-big-play-button:focus,
.vjs-big-play-button:hover {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: none;
    background: url('./../../icons/video-icons/BigPlayButton.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: none !important;
    height: 90px;
    width: 90px;
    padding: 0px;
}

.vjs-button,
.vjs-control,
.vjs-icon-placeholder {
    font-family: $controls-font !important;
    color: $controls-inactive-color !important;
    bottom: 0px;
}
.vjs-control:hover:before,
.vjs-control.focus:before,
.vjs-button:hover .vjs-control:before {
    font-family: $controls-font !important;
    color: $controls-active-color !important;
}

.theoplayer-skin .vjs-control-bar {
    margin-top: $controls-padding-top;
    background: none;
    background-image: linear-gradient(to top, rgba(0, 15, 44), rgba(0, 15, 44, 0));
}
.theoplayer-skin .vjs-progress-holder,
.vjs-progress-control {
    border-radius: 4px;
}
.vjs-slider-bar {
    border-radius: 4px;
}

.vjs-load-progress {
    > div {
        border-radius: 4px;
    }
    border-radius: 4px;
}

.theo-verizonmedia-marker-asset {
    border: none;
}

.vjs-icon-play:before,
.video-js .vjs-play-control:before,
.video-js .vjs-big-play-button:before {
    font-family: $controls-font !important;
}

.vjs-icon-pause:before,
.video-js .vjs-play-control.vjs-playing:before {
    font-family: $controls-font !important;
}

.vjs-play-control.vjs-playing:hover .vjs-icon-placeholder:before,
.vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
    font-family: $controls-font !important;
}
.vjs-icon-volume-high:before,
.video-js .vjs-mute-control:before,
.video-js .vjs-volume-menu-button:before {
    font-family: $controls-font !important;
}
.vjs-icon-volume-mid:before,
.video-js .vjs-mute-control.vjs-vol-2:before,
.video-js .vjs-volume-menu-button.vjs-vol-2:before {
    font-family: $controls-font !important;
}
.vjs-icon-volume-low:before,
.video-js .vjs-mute-control.vjs-vol-1:before,
.video-js .vjs-volume-menu-button.vjs-vol-1:before {
    font-family: $controls-font !important;
}
.vjs-icon-volume-mute:before,
.video-js .vjs-mute-control.vjs-vol-0:before,
.video-js .vjs-volume-menu-button.vjs-vol-0:before {
    font-family: $controls-font !important;
}

// new seek buttons
.seek-forward-button {
    order: -100; /* to the right of the play/pause-button */
    cursor: pointer;
}
.seek-forward-button::before {
    font-family: $controls-font !important;
    content: '\e901';
}
.seek-back-button {
    order: -101; /* to the left of the play/pause-button */
    cursor: pointer;
}
.seek-back-button::before {
    font-family: $controls-font !important;
    content: '\e902';
}
// Duration placement
.vjs-current-time-display {
    line-height: $button-height;
}
.vjs-time-divider > div {
    line-height: $button-height;
}
.vjs-duration-display {
    line-height: $button-height;
}

//control bar sizing
.vjs-control-bar {
    height: $button-height !important;
}
.vjs-control {
    height: $button-height !important;
}
.vjs-control:before {
    font-size: $controls-size !important;
    line-height: $button-height !important;
}

.vjs-progress-control {
    height: 6px !important;
}

.vjs-mute-control {
    order: 99 !important;
}

.vjs-volume-control {
    order: 98 !important;
}
.theoplayer-skin .theo-settings-control-menu-item-hd-label {
    display: inline;
    content: 'HD';
    font-weight: bold;
    font-size: 0.7em;
    margin-left: 0.2em;
    position: absolute;
    top: 0;
    right: 0.6em;
}

//use custom font for buttons
.vjs-icon-play,
.video-js .vjs-play-control {
    order: -102;
}

.vjs-icon-play:before,
.video-js .vjs-play-control:before,
.video-js .vjs-big-play-button:before {
    content: '\f101';
}

.vjs-icon-play-circle:before {
    content: '\f102';
}

.vjs-icon-pause:before,
.video-js .vjs-play-control.vjs-playing:before {
    content: '\f103';
}

.vjs-icon-volume-mute:before,
.video-js .vjs-mute-control.vjs-vol-0:before,
.video-js .vjs-volume-menu-button.vjs-vol-0:before {
    content: '\f104';
}

.vjs-icon-volume-low:before,
.video-js .vjs-mute-control.vjs-vol-1:before,
.video-js .vjs-volume-menu-button.vjs-vol-1:before {
    content: '\f105';
}
.vjs-icon-volume-mid:before,
.video-js .vjs-mute-control.vjs-vol-2:before,
.video-js .vjs-volume-menu-button.vjs-vol-2:before {
    content: '\f106';
}

.vjs-icon-volume-high:before,
.video-js .vjs-mute-control:before,
.video-js .vjs-volume-menu-button:before {
    content: '\f107';
}

.vjs-icon-fullscreen-enter:before,
.video-js .vjs-fullscreen-control:before {
    content: '\f108';
}

.vjs-icon-fullscreen-exit:before,
.video-js.vjs-fullscreen .vjs-fullscreen-control:before {
    content: '\f109';
}

.vjs-icon-square:before {
    content: '\f10a';
}

.vjs-icon-spinner:before {
    content: '\f10b';
}

.vjs-icon-subtitles:before,
.video-js .vjs-subtitles-button:before {
    content: '\f10c';
}

.vjs-icon-captions:before,
.video-js .vjs-captions-button:before {
    content: '\f10d';
}

.vjs-icon-chapters:before,
.video-js .vjs-chapters-button:before {
    content: '\f10e';
}

.vjs-icon-share:before {
    content: '\f10f';
}

.vjs-icon-cog:before {
    content: '\f110';
}

.vjs-icon-circle:before,
.theoplayer-skin .theo-live-control-indicator:before,
.video-js .vjs-volume-level:before,
.video-js .vjs-play-progress:before,
.video-js .vjs-mouse-display:before {
    content: '\f111';
}

.vjs-icon-circle-outline:before {
    content: '\f112';
}

.vjs-icon-circle-inner-circle:before {
    content: '\f113';
}

.vjs-icon-hd:before {
    content: '\f114';
}

.vjs-icon-cancel:before,
.video-js .vjs-control.vjs-close-button:before {
    content: '\f115';
}

.vjs-icon-replay:before,
.theoplayer-skin.vjs-ended .vjs-play-control:before {
    content: '\f116';
}

.vjs-icon-facebook:before {
    content: '\f117';
}

.vjs-icon-gplus:before {
    content: '\f118';
}

.vjs-icon-linkedin:before {
    content: '\f119';
}

.vjs-icon-twitter:before {
    content: '\f11a';
}

.vjs-icon-tumblr:before {
    content: '\f11b';
}

.vjs-icon-pinterest:before {
    content: '\f11c';
}

.vjs-icon-audio-description:before,
.video-js .vjs-descriptions-button:before {
    content: '\f11d';
}

.vjs-icon-audio:before,
.video-js .vjs-audio-button:before {
    content: '\f11e';
}

.vjs-icon-next-item:before {
    content: '\e11f';
}

.vjs-icon-previous-item:before {
    content: '\e120';
}

.vjs-icon-picture-in-picture:before {
    content: '\e121';
}

.vjs-icon-picture-in-picture-enter:before {
    content: '\f122';
}

.vjs-icon-picture-in-picture-exit:before {
    content: '\f123';
}

.vjs-icon-view-module:before {
    content: '\f124';
}

.vjs-icon-close:before,
.theoplayer-skin .theo-close-button:before {
    content: '\f125';
}

.vjs-icon-cardboard:before,
.theoplayer-skin .theo-vr-button:before {
    content: '\f126';
}

.vjs-icon-view-grid:before {
    content: '\f127';
}

.vjs-icon-multi-view:before {
    content: '\f128';
}

.vjs-icon-expand-less:before {
    content: '\f129';
}

.vjs-icon-expand-more:before {
    content: '\f12a';
}

.theo-airplay-button:before {
    content: '\e903';
}

.theo-chromecast-button:before {
    content: '\e904';
}

.theo-cast-button {
    display: none !important; // disable cast button
    .theo-button-icon-blur {
        display: none;
    }
    .theo-cast-svg-container {
        display: none;
    }
}

.preview-theo-player.theoplayer-skin,
.preview-theo-player.theoplayer-skin:hover {
    .vjs-loading-spinner {
        display: none;
    }
    .vjs-big-play-button,
    .vjs-big-play-button:focus,
    .vjs-big-play-button:hover {
        display: none;

        .theo-big-play-button-svg-container > svg {
            display: none;
        }
    }
}

.vjs-icon-cog:before {
    content: '\f110';
}

.icon-live-cog:before {
    content: '\f110';
}

.icon-live-cog-hd:before {
    content: '\e91e';
}

.icon-caption-audio:before {
    content: '\e949';
}
