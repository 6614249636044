@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/theme-variables';

.button {
    padding: 0;
    position: relative;
    float: left;
    background: transparent;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    transition: opacity 0.25s ease;
    border-radius: $ff-border-radius-sm;
    border: none;
    cursor: pointer;

    span {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        left: 50%;
        width: 48px;
        height: 48px;
        border: 2px solid $ff-color-blue-lighter;
        border-radius: 50%;
        transform: translate(-50%, -50%);

        > svg {
            width: 18px;
            height: 18px;
        }
    }

    svg path {
        stroke: $ff-color-blue-lighter;
    }

    &:active {
        box-shadow: 0 0 0 1px rgba($ff-color-blue-cinema, 1);
    }

    &:hover {
        span {
            border: 2px solid $ff-color-blue-dark-tint;
        }
        svg path {
            stroke: $ff-color-blue-dark-tint;
        }
    }
}

.disabled.button {
    opacity: 0.3;
    cursor: default;
}
