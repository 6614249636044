@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/theme-variables';

$live-match-header-height: fit-content;
$live-match-header-height-top: 5.875rem;
$live-match-header-height-bot: 3.5rem;
$live-match-header-margin: 5rem;
$live-match-country-padding: 1.25rem;
$live-match-country-name-margin-top: 0.313rem;
$live-match-score-time-margin-left-right: 1rem;

.liveMatchHeader {
    @include flex-box(null, center, column);

    position: relative;
    height: $live-match-header-height;
    background-color: white;
    width: 100%;
    box-shadow: 0px 25px 40px rgba(29, 42, 69, 0.08);

    .chevronIcon {
        stroke: $ff-color-blue-lighter;
        width: 20px;
        margin-bottom: 14px;
    }

    .isToggled .chevronIcon {
        transform: rotate(180deg);
        stroke: $ff-color-blue-dark-tint;
    }

    .filesDownloadButton {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 360px;
        border: 0px solid $ff-color-blue-lighter;
        background-color: $ff-color-white;
        color: $ff-color-blue-lighter;
        font-size: #{map-get($ff-font-sizes-array, '16')}rem;
        font-weight: $ff-font-weight-bold;
        p {
            margin: 0 8px 0 0;
        }

        &.isToggled {
            color: $ff-color-blue-dark-tint;
            border-color: $ff-color-blue-dark-tint;
        }
    }

    .liveMatchHeaderTop {
        height: $live-match-header-height-top;
        background-color: white;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        flex-basis: 0;

        .bannerAndControls {
            @include flex-box(null, center, row);

            column-gap: 1rem;
            margin-left: $live-match-header-margin;

            .arrows {
                display: flex;
                flex-direction: row;
                column-gap: 0.5rem;
            }
        }

        .viewMore {
            @include flex-box(center, center, row);

            margin-right: $live-match-header-margin;
            font-family: $ff-font-family-open-sans-regular;
            column-gap: 0.5rem;
            margin-top: 2rem;

            .label {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: $ff-color-blue-lighter;
            }
            .arrow {
                margin-top: 3%;
            }

            svg {
                path {
                    stroke: $ff-color-blue-lighter;
                    stroke-width: '2';
                    stroke-linecap: 'round';
                    stroke-linejoin: 'round';
                }
            }
        }
    }
}

.noMatchTodayText {
    text-transform: none;
}
