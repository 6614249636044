@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.breadcrumbsList {
    align-items: center;
    gap: $ff-spacing-1;

    @include breakpoint(md, up) {
        gap: 0;
    }

    .breadcrumbsItem {
        display: flex;
        align-items: center;

        &:first-of-type {
            .arrow {
                display: none;
            }
        }
    }

    .arrow {
        max-width: 10px;
        margin: 0 calc($ff-spacing-1 + $ff-spacing-2);
    }

    .backChevron {
        path {
            stroke: var(--color, $ff-color-blue-lighter);
        }
    }

    a,
    p {
        @include set-font-and-line($ff-font-size-9, $ff-line-height-160);
        font-weight: $ff-font-weight-semibold;
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }

    a {
        color: $ff-color-blue-lighter;
    }
}
