@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

$mobile-header-bar-height: 55px;

.firstLevelOverlay {
    position: fixed;
    top: $mobile-header-bar-height;
    left: 0;
    right: 0;
    height: calc(100vh - $mobile-header-bar-height - $mobile-bottom-nav-height);
    background-color: $ff-color-blue-night;
    border-top: 1px solid $ff-color-grey-steel;
    border-bottom: 1px solid $ff-color-grey-steel;
    overflow-y: scroll;
}

.firstLevelTitleContainer {
    position: sticky;
    top: 0;
    gap: 1rem;
    background-color: $ff-color-grey-snow;
    cursor: pointer;

    .firstLevelTitle {
        color: $ff-color-blue-night;
    }

    .icon {
        path {
            stroke: $ff-color-blue-night;
        }
    }
}

.firstLevelTitle {
    @include font(
        $ff-font-h6-array,
        $ff-line-height-150,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '0'),
        false
    );
    cursor: pointer;
    font-weight: $ff-font-weight-bold;
    color: $ff-color-white;
}

.icon {
    width: 18px;
    height: 18px;
    path {
        stroke: $ff-color-white;
    }
}
