@include add-font('Fifa LT 95 Ultra Black', url('./../assets/fonts/Frutiger LT 95 Ultra Black.ttf'), null, null, null);
@include add-font(
    'Fifa LT 76 Black Italic',
    url('./../assets/fonts/Frutiger LT 76 Black Italic.ttf'),
    null,
    null,
    null
);
@include add-font('Fifa LT 75 Black', url('./../assets/fonts/Frutiger LT 75 Black.ttf'), null, null, null);
@include add-font('Fifa LT 66 Bold Italic', url('./../assets/fonts/Frutiger LT 66 Bold Italic.ttf'), null, null, null);
@include add-font('Fifa LT 65 Bold', url('./../assets/fonts/Frutiger LT 65 Bold.ttf'), null, null, null);
@include add-font('Fifa LT 56 Italic', url('./../assets/fonts/Frutiger LT 56 Italic.ttf'), null, null, null);
@include add-font('Fifa LT 55 Roman', url('./../assets/fonts/Frutiger LT 55 Roman.ttf'), null, null, null);
@include add-font(
    'Fifa LT 46 Light Italic',
    url('./../assets/fonts/Frutiger LT 46 Light Italic.ttf'),
    null,
    null,
    null
);
@include add-font(
    'Open Sans Light',
    url('./../assets/fonts/OpenSans-Light.woff2'),
    300,
    normal,
    url('./../assets/fonts/OpenSans-Light.ttf')
);
@include add-font(
    'Open Sans Regular',
    url('./../assets/fonts/OpenSans-Regular.woff2'),
    400,
    normal,
    url('./../assets/fonts/OpenSans-Regular.ttf')
);
@include add-font(
    'Open Sans Italic',
    url('./../assets/fonts/OpenSans-Italic.woff2'),
    400,
    italic,
    url('./../assets/fonts/OpenSans-Italic.ttf')
);
@include add-font(
    'Open Sans Semibold',
    url('./../assets/fonts/OpenSans-SemiBold.woff2'),
    600,
    normal,
    url('./../assets/fonts/OpenSans-SemiBold.ttf')
);
@include add-font(
    'Open Sans Bold',
    url('./../assets/fonts/OpenSans-Bold.woff2'),
    700,
    normal,
    url('./../assets/fonts/OpenSans-Bold.ttf')
);
@include add-font(
    'Open Sans Condensed',
    url('./../assets/fonts/OpenSansCondensed-Bold.woff2'),
    700,
    normal,
    url('./../assets/fonts/OpenSansCondensed-Bold.woff2')
);
@include add-font('Poppins', url('./../assets/fonts/Poppins-Regular.ttf'), 400, normal, null);

$link-text-color: $theme-color-a-element;
$link-text-hover-color: $theme-color-a-element-hover;
$link-border-focus-color: $theme-color-a-element-border-focus;

html {
    font-family: $ff-font-family-open-sans-regular, $ff-font-family-fifa-font-45-light;
    i {
        font-family: $ff-font-family-fifa-font-46-light-italic, $ff-font-family-open-sans-italic;
    }
}

h1 {
    @include font-with-variable-line-height(
        $ff-font-h1-array,
        $ff-line-height-array-h1,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '32'),
        true
    );

    &.unsetHeading {
        text-transform: unset;
    }
}

h2 {
    @include font-with-variable-line-height(
        $ff-font-h2-array,
        $ff-line-height-array-h2,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '24'),
        true
    );

    &.unsetHeading {
        text-transform: unset;
    }

    i {
        font-family: $ff-font-family-fifa-font-76-black-italic;
    }
}

h3 {
    @include font-with-variable-line-height(
        $ff-font-h3-array,
        $ff-line-height-array-h3,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '16'),
        true
    );

    &.unsetHeading {
        text-transform: unset;
    }

    i {
        font-family: $ff-font-family-fifa-font-66-bold-italic;
    }
}

h4 {
    @include font-with-variable-line-height(
        $ff-font-h4-array,
        $ff-line-height-array-h4,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '16'),
        true
    );

    &.unsetHeading {
        text-transform: unset;
    }

    i {
        font-family: $ff-font-family-fifa-font-56-italic, $ff-font-family-open-sans-italic;
    }
}

h5 {
    @include font-with-variable-line-height(
        $ff-font-h5-array,
        $ff-line-height-array-h5,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '16'),
        true
    );

    &.unsetHeading {
        text-transform: unset;
    }

    i {
        font-family: $ff-font-family-fifa-font-56-italic, $ff-font-family-open-sans-italic;
    }
}

h6 {
    @include font-with-variable-line-height(
        $ff-font-h6-array,
        $ff-line-height-array-h6,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '16'),
        true
    );

    &.unsetHeading {
        text-transform: unset;
    }

    i {
        font-family: $ff-font-family-fifa-font-56-italic, $ff-font-family-open-sans-italic;
    }
}

p,
.p {
    @include font-with-variable-line-height(
        $ff-font-p-array,
        $ff-line-height-array-p,
        $ff-font-family-open-sans-regular
    );
}

a,
.a {
    @include font-with-variable-line-height(
        $ff-font-a-array,
        $ff-line-height-link-array,
        $ff-font-family-open-sans-regular
    );
    color: $link-text-color;
    text-decoration: none;
    outline: none;
    border-radius: $ff-border-radius-sm;

    &:hover {
        color: $link-text-hover-color;
    }

    &:focus,
    &:active {
        box-shadow: 0px 0px 0px $ff-border-button-width $link-border-focus-color;
        z-index: $ff-z-index-6;
    }
}

/* Adjust link font size inside rich text for normal paragraph */
p:not(.p-large) {
    a {
        font-size: inherit;
    }
}

// CLASSES ARE BELOW IN ORDER TO OVERWRITE WITHOUT USING !IMPORTANT
.h1 {
    @include font-with-variable-line-height(
        $ff-font-h1-array,
        $ff-line-height-array-h1,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '32'),
        true
    );
}

.h2 {
    @include font-with-variable-line-height(
        $ff-font-h2-array,
        $ff-line-height-array-h2,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '24'),
        true
    );

    i {
        font-family: $ff-font-family-fifa-font-76-black-italic;
    }
}

.h3 {
    @include font-with-variable-line-height(
        $ff-font-h3-array,
        $ff-line-height-array-h3,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '16'),
        true
    );

    i {
        font-family: $ff-font-family-fifa-font-66-bold-italic;
    }
}

.h4 {
    @include font-with-variable-line-height(
        $ff-font-h4-array,
        $ff-line-height-array-h4,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '16'),
        true
    );

    i {
        font-family: $ff-font-family-fifa-font-56-italic, $ff-font-family-open-sans-italic;
    }
}

.h5 {
    @include font-with-variable-line-height(
        $ff-font-h5-array,
        $ff-line-height-array-h5,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '16'),
        true
    );

    i {
        font-family: $ff-font-family-fifa-font-56-italic, $ff-font-family-open-sans-italic;
    }
}

.h6 {
    @include font-with-variable-line-height(
        $ff-font-h6-array,
        $ff-line-height-array-h6,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '16'),
        true
    );

    i {
        font-family: $ff-font-family-fifa-font-56-italic, $ff-font-family-open-sans-italic;
    }
}

.h1-article {
    @include font-with-variable-line-height(
        $ff-font-h1-article-array,
        $ff-line-height-array-article-h1,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '16'),
        false
    );
    @include removeUpperSpaceFromFirstLine($ff-line-height-array-article-h1);
    font-stretch: $ff-font-stretch-75;
}

.h2-article {
    @include font-with-variable-line-height(
        $ff-font-h2-article-array,
        $ff-line-height-array-article-h2,
        $ff-font-family-open-sans-light,
        map-get($ff-spacing-sizes, '16'),
        false
    );
    @include removeUpperSpaceFromFirstLine($ff-line-height-array-article-h2);
}

.h3-article {
    @include font-with-variable-line-height(
        $ff-font-h3-article-array,
        $ff-line-height-array-article-h3,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '16'),
        false
    );
    @include removeUpperSpaceFromFirstLine($ff-line-height-array-article-h3);
    font-stretch: $ff-font-stretch-75;
}

.h4-article {
    @include font-with-variable-line-height(
        $ff-font-h4-article-array,
        $ff-line-height-array-article-h4,
        $ff-font-family-open-sans-regular,
        map-get($ff-spacing-sizes, '16'),
        false
    );
    @include removeUpperSpaceFromFirstLine($ff-line-height-array-article-h4);

    b,
    strong {
        @include breakpoint('md', up) {
            font-stretch: $ff-font-stretch-75;
            @include set-line-height($ff-line-height-130);
        }
    }
}

.h5-article {
    @include font-with-variable-line-height(
        $ff-font-h5-article-array,
        $ff-line-height-array-article-h5,
        $ff-font-family-open-sans-regular,
        map-get($ff-spacing-sizes, '16'),
        false
    );
    @include removeUpperSpaceFromFirstLine($ff-line-height-array-article-h5);
}

.h6-article {
    @include font-with-variable-line-height(
        $ff-font-h6-article-array,
        $ff-line-height-array-article-h6,
        $ff-font-family-open-sans-regular,
        map-get($ff-spacing-sizes, '16'),
        false
    );
    @include removeUpperSpaceFromFirstLine($ff-line-height-array-article-h6);
}

.p-article {
    @include font-with-variable-line-height(
        $ff-font-paragraph-article-array,
        $ff-line-height-array-article-paragraph,
        $ff-font-family-open-sans-regular,
        map-get($ff-spacing-sizes, '16'),
        false
    );
    @include removeUpperSpaceFromFirstLine($ff-line-height-array-article-paragraph);
}

.h-error {
    font-family: $ff-font-family-open-sans-condensed;
    color: $ff-color-blue-cinema;
    font-size: 5.5rem;
    text-transform: uppercase;

    @include breakpoint(md, up) {
        color: $ff-color-white;
    }

    @include breakpoint(lg, up) {
        font-size: 6.247rem;
    }
}

.p-large {
    @include font-with-variable-line-height(
        $ff-font-p-large-array,
        $ff-line-height-array-p-large,
        $ff-font-family-open-sans-regular,
        map-get($ff-spacing-sizes, '16'),
        false
    );
    &:not(.pagination) {
        @include removeUpperSpaceFromFirstLine($ff-line-height-array-p-large);
    }
}

.p-error {
    font-family: $ff-font-family-open-sans-condensed;
    font-size: #{map-get($ff-font-sizes-array, '24')}rem;
    color: $ff-color-blue-cinema;
    text-transform: uppercase;

    @include breakpoint(md, up) {
        color: $ff-color-white;
    }
}

ul.unordered-list {
    padding-left: $ff-spacing-4;
    li {
        list-style-type: disc;
        position: relative;
        padding-left: $ff-spacing-2;
    }
}

ol.ordered-list {
    counter-reset: customCounter 0;
    padding-left: $ff-spacing-3;
    padding-right: $ff-spacing-3;
    > li {
        counter-increment: customCounter;
        padding-left: $ff-spacing-3;
        padding-right: $ff-spacing-3;
        position: relative;
        &::before {
            content: counter(customCounter) '.';
            padding-right: $ff-spacing-3;
            padding-top: 4px;
            position: absolute;
            inset-inline-start: -1rem;
        }

        ol.ordered-list {
            > li {
                vertical-align: middle;
                &::before {
                    content: counter(customCounter, upper-alpha) '.';
                }

                ol.ordered-list {
                    > li {
                        &::before {
                            content: counter(customCounter, lower-roman) '.';
                        }

                        ol.ordered-list {
                            > li {
                                &::before {
                                    content: counter(customCounter, lower-alpha) '.';
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

ul.unordered-list,
ol.ordered-list {
    li.li {
        color: $ff-color-grey-slate;
        margin: $ff-spacing-2 0;
        p {
            display: contents;
        }
    }
}

hr.hr {
    height: 1px;
    background-color: $ff-color-grey-slate;
    border: 0;
    margin-top: $ff-spacing-5;
    margin-bottom: $ff-spacing-5;
}

blockquote.blockquote {
    border-left: 3px solid $ff-color-grey-steel;
    padding-left: $ff-spacing-3;
    p {
        &:last-child {
            font-style: italic;
        }
        &:first-child {
            font-style: normal;
        }
    }
}

.caption,
.details,
.credits {
    @include font-with-variable-line-height(
        $ff-font-caption-array,
        $ff-line-height-array-caption,
        $ff-font-family-open-sans-regular,
        map-get($ff-spacing-sizes, '16'),
        false
    );
}

.link {
    @include font-with-variable-line-height(
        $ff-font-link-array,
        $ff-line-height-link-array,
        $ff-font-family-open-sans-regular,
        map-get($ff-spacing-sizes, '16'),
        false
    );
}

.link-large {
    @include font-with-variable-line-height(
        $ff-font-link-large-array,
        $ff-line-height-link-large-array,
        $ff-font-family-open-sans-bold,
        map-get($ff-spacing-sizes, '16'),
        false
    );
}

.link-small {
    @include font-with-variable-line-height(
        $ff-font-link-small-array,
        $ff-line-height-link-small-array,
        $ff-font-family-open-sans-bold,
        map-get($ff-spacing-sizes, '16'),
        false
    );
}

.overline,
.dates {
    @include font-with-variable-line-height(
        $ff-font-overline-array,
        $ff-line-height-overline-array,
        $ff-font-family-open-sans-semibold,
        map-get($ff-spacing-sizes, '16'),
        false
    );
}

.card-heading-large {
    @include font-with-variable-line-height(
        $ff-font-card-heading-large-array,
        $ff-line-height-card-heading-large-array,
        $ff-font-family-open-sans-semibold,
        map-get($ff-spacing-sizes, '16'),
        false
    );
}

.card-heading {
    @include font-with-variable-line-height(
        $ff-font-card-heading-array,
        $ff-line-height-card-heading-array,
        $ff-font-family-open-sans-semibold,
        map-get($ff-spacing-sizes, '16'),
        false
    );
}

.card-heading-small {
    @include font-with-variable-line-height(
        $ff-font-card-heading-small-array,
        $ff-line-height-card-heading-small-array,
        $ff-font-family-open-sans-semibold,
        map-get($ff-spacing-sizes, '16'),
        false
    );
}

.card-heading-tiny {
    @include font-with-variable-line-height(
        $ff-font-card-heading-tiny-array,
        $ff-line-height-card-heading-tiny-array,
        $ff-font-family-open-sans-semibold,
        map-get($ff-spacing-sizes, '16'),
        false
    );
}

.header-dropdown-small {
    @include font-with-variable-line-height(
        $ff-font-overline-array,
        $ff-line-height-array-p-large,
        $ff-font-family-open-sans-regular,
        map-get($ff-spacing-sizes, '0'),
        false
    );
}
