//Import partials
@import './abstracts/variables';
@import './abstracts/functions';
@import './abstracts/mixins';
@import './abstracts/theme-variables';

@import './vendors/bootstrap';
@import './vendors/date-range';
@import './vendors/theo-player';
@import './vendors/slick-carousel';

@import './vendors/react-modal';

@import './themes/fmh-theme';

@import './base/reset';
@import './base/typography';
@import './base/utils';

[class^='ecEmbedWrapper'] {
    z-index: $ff-z-index-modal-overlay !important;

    > button {
        bottom: 90px !important;
    }
}

body {
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-gutter: stable;
}

//styles from fifa.cxm.design.system
@import 'fifa.cxm.design.system/styles/colors';
@import 'fifa.cxm.design.system/styles/spacings';
@import 'fifa.cxm.design.system/styles/containers';
@import 'fifa.cxm.design.system/styles/typography';
@import 'fifa.cxm.design.system/styles/grid';
@import 'fifa.cxm.design.system/styles/corner-radius';
@import 'fifa.cxm.design.system/styles/shadows';
@import 'fifa.cxm.design.system/index';
