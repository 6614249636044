@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.outerDiv {
    position: relative;
    height: 32px;
    width: 32px;
    overflow: hidden;
    background-color: white;
    border: 1px solid $ff-color-white;
}

.rounded {
    border-radius: $ff-border-radius-circle;
}

.squared {
    border-radius: $ff-border-radius-md;
}

.image {
    width: 100%;
}

.userIcon {
    margin-top: 3px;

    @include breakpoint(md, down) {
        svg {
            height: 20px;
            width: 20px;

            path {
                fill: $ff-color-grey-steel;
            }
        }
    }
}
