// Slider
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    padding-bottom: calc(#{map-get($map: $ff-spacing-sizes, $key: '64')} * 2);
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

// Arrows
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    bottom: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    border-radius: 50%;
    outline: none;
    background: transparent;

    &:hover,
    &:focus {
        color: transparent;
        outline: none;
        background: transparent;

        &:before {
            opacity: 1;
        }
    }

    &.slick-disabled {
        &:before {
            opacity: 0.25;
        }
    }

    &:before {
        font-family: 'slick';
        font-size: 20px;
        line-height: 1;
        opacity: 0.75;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &:before,
    &:after {
        width: 48px;
        height: 48px;
    }
}

.slick-prev {
    left: -25px;

    &:before {
        content: '←';
    }
}

[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;

    &:before {
        content: '→';
    }
}

.slide-next {
    right: -25px;

    &:before {
        content: '→';
    }
}

[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;

    &:before {
        content: '←';
    }
}

// Dots
.slick-dotted {
    .slick-slider {
        margin-bottom: 30px;
    }
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;

            &:hover,
            &:focus {
                outline: none;

                &:before {
                    opacity: 1;
                }
            }

            &:before {
                font-size: 6px;
                line-height: 20px;
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                content: '•';
                text-align: center;
                opacity: 0.25;
                color: black;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.slick-active {
            button {
                &:before {
                    opacity: 0.75;
                    color: black;
                }
            }
        }
    }
}
