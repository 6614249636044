@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.rectangle,
.rectangle2,
.rectangle3 {
    border-radius: $ff-border-radius-md;

    position: relative;
    overflow: hidden;
    background: $ff-color-grey-lightest;
    z-index: 1;

    &::after {
        @include skeleton-animation($ff-color-grey-lightest, $ff-color-grey-steel);
    }
}

.rectangle {
    width: 104px;
    height: 18px;

    &.titleSkeleton {
        margin-top: calc(57px - 1em);
    }
}

.rectangle2 {
    width: 141px;
    height: 60px;
}

.rectangle3 {
    width: 160px;
    height: 40px;
}

.logoStrips {
    gap: $ff-spacing-4;
    padding: calc(57px - 1em) 0;
}

.footer {
    margin-bottom: 72px;
    position: relative;
    background: $ff-color-blue-night;

    @include breakpoint(md, up) {
        height: 102px;
    }

    @include breakpoint(lg, up) {
        height: 86px;
        margin-bottom: 0;
    }

    @include breakpoint(xl, up) {
        height: 65px;
    }

    overflow: hidden;
    z-index: 1;

    .wrapper {
        gap: $ff-spacing-4;
        height: 100%;

        &::after {
            @include skeleton-animation($ff-color-blue-night, $ff-color-blue-logo);
            top: 0;
        }
    }
}
