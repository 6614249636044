@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: $ff-spacing-4;

    @include breakpoint(md, up) {
        grid-template-columns: repeat(2, 1fr); // 2 columns for medium screens
    }

    @include breakpoint(lg, up) {
        grid-template-columns: repeat(3, 1fr); // 3 columns for large screens
    }

    @include breakpoint(xl, up) {
        grid-template-columns: repeat(4, 1fr); // 4 columns for medium screens
    }

    .square {
        width: 100%;
        height: 200px;
        border-radius: $ff-border-radius-md;

        position: relative;
        overflow: hidden;
        background: $ff-color-grey-steel;

        &::after {
            @include skeleton-animation($ff-color-grey-steel, $ff-color-grey-lightest);
        }
    }
}
