@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.menuDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: $ff-color-grey-snow;
    height: 4.375rem;
    position: relative;

    > div,
    > div > ul,
    > div > ul > li {
        height: 100%;
    }

    > div > ul {
        gap: $ff-spacing-5;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(4px) brightness(50%);
    z-index: $ff-z-index-5;
}
