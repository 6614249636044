@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    color: $ff-color-grey-steel !important;
    padding: $ff-spacing-3 0;
    height: 100%;
    @include font(
        (
            xs: $ff-font-size-10,
        ),
        $ff-line-height-150,
        $ff-font-family-open-sans-semibold,
        0,
        false
    );
    cursor: pointer;

    &:focus,
    &:active {
        box-shadow: none;
    }
}

.active {
    color: $ff-color-white !important;
}

.root svg path {
    fill: $ff-color-grey-steel;
}

.active svg path {
    fill: $ff-color-white;
}

.root div {
    width: 24px;
    height: 24px;
}
