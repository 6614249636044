@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/theme-variables';

$top-menu-mobile-position: $header-bar-height - 0.0625rem;

.header {
    background-color: $ff-color-blue-cinema;
    width: 100vw;

    .mainNav {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $ff-color-blue-cinema;
        z-index: $ff-z-index-header-nav;
        height: $header-bar-height;

        @include breakpoint(lg, up) {
            justify-content: center;
        }
    }

    .link {
        margin-bottom: 0;

        svg {
            width: fit-content;
            height: 1rem;
        }

        &:focus:not(:focus-visible) {
            box-shadow: none;
        }
    }

    .user {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 0;

        .text {
            color: $ff-color-white;
            text-decoration: none;
            text-transform: none;
            list-style-type: none;
            @include font(
                (
                    xs: $ff-font-size-9,
                ),
                $ff-line-height-160,
                null,
                map-get($ff-spacing-sizes, '0'),
                false
            );

            a {
                color: $ff-color-white;
                font-size: #{map-get($ff-font-sizes-array, '12')}rem;
                transition: color linear 0.1s;
            }

            &:hover {
                a {
                    color: darken($ff-color-white, 15%);
                }
            }
        }

        .icon {
            display: none;
        }
    }

    .searchIcon {
        cursor: pointer;

        svg {
            path {
                fill: $ff-color-grey-steel;
            }
        }

        &:hover,
        &.activeSearchIcon {
            svg {
                path {
                    fill: $ff-color-white;
                }
            }
        }

        @include breakpoint(lg, up) {
            margin: 0 $ff-spacing-3;
            margin-inline-start: auto;
        }
    }

    .burgerMenu {
        display: none;
    }

    .topMenu {
        position: relative;
        z-index: $ff-z-index-header-menu;
        height: $header-menu-bar-height;
        width: 100%;
    }

    .searchWrapper {
        @include breakpoint(lg, up) {
            position: absolute;
            right: 0;

            &[dir='rtl'] {
                right: unset;
                left: 0;
            }
        }
    }
}

@include breakpoint(lg, down) {
    .header {
        .mainNav {
            display: flex;
            justify-content: space-between;
            z-index: auto;
            height: $header-menu-bar-height;
        }

        .link {
            svg {
                height: 1rem;
            }
        }

        .user {
            justify-self: center;
            align-self: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 2px;

            .icon {
                display: inline;
                path {
                    stroke-width: 1px;
                }
            }

            .profileIcon {
                background: none;
                color: inherit;
                border: none;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: inherit;
            }
        }

        .text {
            display: none;
        }

        .topMenu {
            display: none;
        }
    }
}
