@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.banner {
    @include flex-box(center, center, row);
    position: relative;
    width: 100%;
    gap: $ff-spacing-3;

    p {
        @include set-font-size($ff-font-size-9);
        margin: 0;
    }

    .icon {
        width: $ff-spacing-4;
        height: $ff-spacing-4;
    }
}

.warning {
    background-color: $ff-color-orange-light;
    color: $ff-color-blue-night;

    svg {
        path {
            fill: $ff-color-orange-dark;
        }
    }
}

.info {
    background-color: $ff-color-blue-opaque;
    color: $ff-color-blue-night;

    svg {
        path {
            fill: $ff-color-blue-logo;
        }
    }
}
