@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.breadcrumbsWrapper {
    .breadcrumbsList {
        align-items: center;

        .breadcrumbsItem {
            display: flex;
            align-items: center;

            &:first-of-type {
                .arrow {
                    display: none;
                }
            }
        }
    }

    .arrow {
        max-width: 10px;
        max-height: 10px;
        margin: 0 calc($ff-spacing-1 + $ff-spacing-2);
        path {
            stroke: $ff-color-blue-cinema;
        }
    }

    a,
    p {
        @include set-font-and-line($ff-font-size-9, $ff-line-height-160);
        font-weight: $ff-font-weight-semibold;
        margin: 0;
        @include line-overflow;
    }

    a {
        color: $ff-color-blue-lighter;
    }
}

.languageDropdownContainer > div {
    width: 100%; // react-dropdown-select package creates this blank div
    display: flex;
    justify-content: flex-end;
}

.languageDropdownContainer {
    min-height: 64px;
}
