@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.thirdLevelTitle {
    cursor: pointer;
    color: $ff-color-blue-night;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
}
