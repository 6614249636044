@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/functions';

$panel-padding: 24px;

.statisticsPanel {
    @include flex-box(center, center, column);
    background-color: $ff-color-blue-dark;
    width: 100%;
    padding-top: $panel-padding;
    justify-items: center;
    border-radius: 0px 0px 10px 10px;

    .event {
        display: grid;
        grid-template-columns: 200px 46px 200px;
        justify-items: center;
        align-items: center;
        grid-template-rows: 8px 8px 8px;
        font-family: $ff-font-family-open-sans-regular;
        font-size: 13px;
        color: white;

        .firstColumn {
            justify-self: end;
        }

        .thirdColumn {
            justify-self: start;
        }

        .element {
            @include flex-box(center, center, row);
            column-gap: 8px;
        }
    }

    .viewMore {
        @include flex-box(center, center, row);

        font-family: $ff-font-family-open-sans-regular;

        .label {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #fed130;
            padding-right: 8px;
        }
        .arrow {
            margin-top: 5%;
        }

        svg {
            path {
                stroke: #fed130;
                stroke-width: '2';
                stroke-linecap: 'round';
                stroke-linejoin: 'round';
            }
        }
    }
}
