@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

@mixin label-size($size) {
    @include font(
        (
            xs: $size,
        ),
        $ff-line-height-120,
        $ff-font-family-open-sans-condensed,
        0,
        false
    );
}

.wrapper {
    background-color: $ff-color-blue-dark-tint;
    border-radius: $ff-border-radius-normal;
    width: fit-content;

    .text {
        color: $ff-color-white;
    }
}

.medium {
    .text {
        @include label-size($ff-font-size-7);
    }
}

.small {
    .text {
        @include label-size($ff-font-size-9);
    }
}
