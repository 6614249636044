// ------ FONTS ------
$ff-font-family-fifa-font-95-ultra-black: 'Fifa LT 95 Ultra Black';
$ff-font-family-fifa-font-88-extra-black-condensed-italic: 'Fifa LT 88 Extra Black Condensed Italic';
$ff-font-family-fifa-font-87-extra-black-condensed: 'Fifa LT 87 Extra Black Condensed';
$ff-font-family-fifa-font-78-black-condensed-italic: 'Fifa LT 78 Black Condensed Italic';
$ff-font-family-fifa-font-77-black-condensed: 'Fifa LT 77 Black Condensed';
$ff-font-family-fifa-font-76-black-italic: 'Fifa LT 76 Black Italic';
$ff-font-family-fifa-font-75-black: 'Fifa LT 75 Black';
$ff-font-family-fifa-font-68-bold-condensed-italic: 'Fifa LT 68 Bold Condensed Italic';
$ff-font-family-fifa-font-67-bold-condensed: 'Fifa LT 67 Bold Condensed';
$ff-font-family-fifa-font-66-bold-italic: 'Fifa LT 66 Bold Italic';
$ff-font-family-fifa-font-65-bold: 'Fifa LT 65 Bold';
$ff-font-family-fifa-font-58-condensed-italic: 'Fifa LT 58 Condensed Italic';
$ff-font-family-fifa-font-57-condensed: 'Fifa LT 57 Condensed';
$ff-font-family-fifa-font-56-italic: 'Fifa LT 56 Italic';
$ff-font-family-fifa-font-55-roman: 'Fifa LT 55 Roman';
$ff-font-family-fifa-font-48-light-condensed-italic: 'Fifa LT 48 Light Condensed Italic';
$ff-font-family-fifa-font-47-light-condensed: 'Fifa LT 47 Light Condensed';
$ff-font-family-fifa-font-46-light-italic: 'Fifa LT 46 Light Italic';
$ff-font-family-fifa-font-45-light: 'Fifa LT 45 Light';
$ff-font-family-open-sans-light: 'Open Sans Light';
$ff-font-family-open-sans-regular: 'Open Sans Regular';
$ff-font-family-open-sans-italic: 'Open Sans Italic';
$ff-font-family-open-sans-semibold: 'Open Sans Semibold';
$ff-font-family-open-sans-bold: 'Open Sans Bold';
$ff-font-family-open-sans-condensed: 'Open Sans Condensed';
$ff-font-family-poppins: 'Poppins';

// ------ FONT SIZES ------
$ff-font-size-1: 7.406;
$ff-font-size-2: 5.825;
$ff-font-size-3: 3.247;
$ff-font-size-4: 2.5;
$ff-font-size-5: 2;
$ff-font-size-6: 1.5;
$ff-font-size-7: 1.25;
$ff-font-size-8: 1;
$ff-font-size-9: 0.75;
$ff-font-size-10: 0.625;
$ff-font-size-11: 1.125;
$ff-font-size-12: 0.875;
$ff-font-size-13: 3;

$ff-font-sizes-array: (
    '10': $ff-font-size-10,
    '12': $ff-font-size-9,
    '14': $ff-font-size-12,
    '16': $ff-font-size-8,
    '18': $ff-font-size-11,
    '20': $ff-font-size-7,
    '24': $ff-font-size-6,
    '32': $ff-font-size-5,
    '40': $ff-font-size-4,
    '48': $ff-font-size-13,
    '52': $ff-font-size-3,
    '94': $ff-font-size-2,
    '118': $ff-font-size-1,
);

$ff-font-p-array: (
    xs: $ff-font-size-8,
);

$ff-font-p-small-array: (
    xs: $ff-font-size-9,
    lg: $ff-font-size-8,
);

$ff-font-p-large-array: (
    xs: $ff-font-size-8,
    xxl: $ff-font-size-7,
);

$ff-font-a-array: (
    xs: $ff-font-size-8,
    xxl: $ff-font-size-7,
);

$ff-font-caption-array: (
    xs: $ff-font-size-9,
    md: $ff-font-size-12,
);

$ff-font-h1-array: (
    xs: $ff-font-size-3,
    md: $ff-font-size-4,
    lg: $ff-font-size-3,
    xxl: $ff-font-size-2,
);

$ff-font-h2-array: (
    xs: $ff-font-size-6,
    md: $ff-font-size-5,
    lg: $ff-font-size-4,
    xxl: $ff-font-size-3,
);

$ff-font-h3-array: (
    xs: $ff-font-size-6,
    md: $ff-font-size-5,
    xxl: $ff-font-size-4,
);

$ff-font-h4-array: (
    xs: $ff-font-size-7,
    md: $ff-font-size-6,
    xxl: $ff-font-size-5,
);

$ff-font-h5-array: (
    xs: $ff-font-size-8,
    md: $ff-font-size-7,
    xxl: $ff-font-size-6,
);

$ff-font-h6-array: (
    xs: $ff-font-size-7,
);

$ff-font-h1-article-array: (
    xs: $ff-font-size-4,
    md: $ff-font-size-13,
);

$ff-font-h1-large-array: (
    xs: $ff-font-size-6,
    md: $ff-font-size-4,
);

$ff-font-h2-article-array: (
    xs: $ff-font-size-5,
    md: $ff-font-size-4,
);

$ff-font-h3-article-array: (
    xs: $ff-font-size-6,
    md: $ff-font-size-5,
);

$ff-font-h4-article-array: (
    xs: $ff-font-size-7,
    md: $ff-font-size-6,
);

$ff-font-h5-article-array: (
    xs: $ff-font-size-11,
    md: $ff-font-size-7,
);

$ff-font-h6-article-array: (
    xs: $ff-font-size-8,
    md: $ff-font-size-11,
);

$ff-font-paragraph-article-array: (
    xs: $ff-font-size-12,
    md: $ff-font-size-8,
);

$ff-font-paragraph-large-array: (
    xs: $ff-font-size-8,
    xxl: $ff-font-size-7,
);

$ff-font-paragraph-array: (
    xs: $ff-font-size-9,
    xxl: $ff-font-size-8,
);

$ff-font-paragraph-caption-array: (
    xs: $ff-font-size-10,
    xxl: $ff-font-size-9,
);

$ff-font-link-large-array: (
    xs: $ff-font-size-8,
    md: $ff-font-size-7,
);

$ff-font-link-array: (
    xs: $ff-font-size-10,
    md: $ff-font-size-9,
    xxl: $ff-font-size-8,
);

$ff-font-link-small-array: (
    xs: $ff-font-size-8,
);

$ff-font-overline-array: (
    xs: $ff-font-size-9,
);

$ff-font-card-heading-large-array: (
    xs: $ff-font-size-6,
);

$ff-font-card-heading-array: (
    xs: $ff-font-size-8,
    md: $ff-font-size-7,
);

$ff-font-card-heading-small-array: (
    xs: $ff-font-size-8,
    md: $ff-font-size-11,
);

$ff-font-card-heading-tiny-array: (
    xs: $ff-font-size-9,
    xxl: $ff-font-size-8,
);

$ff-font-search-input-array: (
    xs: $ff-font-size-8,
    md: $ff-font-size-6,
);

// ------ LINE HEIGHTS ------
$ff-line-height-80: 0.8;
$ff-line-height-90: 0.9;
$ff-line-height-100: 1;
$ff-line-height-110: 1.1;
$ff-line-height-120: 1.2;
$ff-line-height-130: 1.3;
$ff-line-height-140: 1.4;
$ff-line-height-150: 1.5;
$ff-line-height-160: 1.6;

// ------ LINE HEIGHT ARRAYS ------
$ff-line-height-array-h1: (
    xs: $ff-line-height-100,
);

$ff-line-height-array-h2: (
    xs: $ff-line-height-100,
);

$ff-line-height-array-h3: (
    xs: $ff-line-height-110,
    xxl: $ff-line-height-100,
);

$ff-line-height-array-h4: (
    xs: $ff-line-height-120,
    md: $ff-line-height-110,
);

$ff-line-height-array-h5: (
    xs: $ff-line-height-120,
);

$ff-line-height-array-h6: (
    xs: $ff-line-height-120,
);

$ff-line-height-array-article-h1: (
    xs: $ff-line-height-130,
    md: $ff-line-height-110,
);

$ff-line-height-array-article-h2: (
    xs: $ff-line-height-130,
);

$ff-line-height-array-article-h3: (
    xs: $ff-line-height-110,
);

$ff-line-height-array-article-h4: (
    xs: $ff-line-height-150,
);

$ff-line-height-array-article-h5: (
    xs: $ff-line-height-150,
);

$ff-line-height-array-article-h6: (
    xs: $ff-line-height-150,
);

$ff-line-height-array-article-paragraph: (
    xs: $ff-line-height-150,
);

$ff-line-height-array-p-large: (
    xs: $ff-line-height-160,
);

$ff-line-height-array-p: (
    xs: $ff-line-height-130,
);

$ff-line-height-array-caption: (
    xs: $ff-line-height-150,
);

$ff-line-height-link-large-array: (
    xs: $ff-line-height-140,
    xxl: $ff-line-height-150,
);

$ff-line-height-link-array: (
    xs: $ff-line-height-140,
    md: $ff-line-height-130,
);

$ff-line-height-link-small-array: (
    xs: $ff-line-height-140,
    xxl: $ff-line-height-130,
);

$ff-line-height-overline-array: (
    xs: $ff-line-height-120,
);

$ff-line-height-card-heading-large-array: (
    xs: $ff-line-height-120,
);

$ff-line-height-card-heading-array: (
    xs: $ff-line-height-130,
    md: $ff-line-height-120,
    xxl: $ff-line-height-130,
);

$ff-line-height-card-heading-small-array: (
    xs: $ff-line-height-130,
);

$ff-line-height-card-heading-tiny-array: (
    xs: $ff-line-height-130,
);

$ff-line-heights-array: ($ff-line-height-100, $ff-line-height-150);

// ------ FONT WEIGHTS ------
$ff-font-weight-light: 300;
$ff-font-weight-normal: 400;
$ff-font-weight-semibold: 600;
$ff-font-weight-bold: 700;

// ------ FONT STRETCHS ------
$ff-font-stretch-100: 100%;
$ff-font-stretch-75: 75%;

// ------ COLORS ------

$ff-color-azure: #17c7d2 !default;
$ff-color-blue-opaque: #d9e6ef;
$ff-color-blue-lighter: #0088e7 !default;
$ff-color-blue-lighter-dark: #0070df !default;
$ff-color-blue-light: #1277d9 !default;
$ff-color-blue: #0369c7 !default;
$ff-color-blue-logo: #045694 !default;
$ff-color-blue-corp: #326295 !default;
$ff-color-blue-corp-dark: #0a5791 !default;
$ff-color-blue-highlight: #005087 !default;
$ff-color-blue-dark: #091b3f !default;
$ff-color-blue-dark-tint: #0263bc !default;
$ff-color-blue-dark-transparent-40: rgba(9, 27, 63, 0.4) !default;
$ff-color-blue-cinema: #000f2c !default;
$ff-color-blue-night: #00182f !default;
$ff-color-blue-night-95: #0d1b37 !default;
$ff-color-blue-night-90: #1a2741 !default;
$ff-color-blue-night-80: #26334c !default;

$ff-color-black: #000 !default;
$ff-color-bronze: #93694e !default;
$ff-color-bronze-gradient: linear-gradient(
    90.36deg,
    #f5bb91 0.09%,
    #dc9e52 36.06%,
    #b57c48 75.35%,
    #c0754b 99.54%
) !default;
$ff-color-bronze-gradient-darkened: linear-gradient(
    90.36deg,
    #c2885e 0.09%,
    #a96b1f 36.06%,
    #824915 75.35%,
    #8d4218 99.54%
) !default;
$ff-color-gold: #837452 !default;
$ff-color-gold-gradient: linear-gradient(
    90.36deg,
    #dfce6d 0.09%,
    #c9b259 36.06%,
    #b59a48 75.35%,
    #ae9142 99.54%
) !default;
$ff-color-gold-gradient-darkened: linear-gradient(
    90.36deg,
    #ac9b3a 0.09%,
    #967f26 36.06%,
    #826715 75.35%,
    #7b5e0f 99.54%
) !default;

$ff-color-green-lighter: #95d463 !default;
$ff-color-green-light: #8dc448 !default;
$ff-color-green: #19af5a !default;
$ff-color-green-dark: #0a6639 !default;
$ff-color-green-success: #27ae5d !default;
$ff-color-green-success-dark: #00b140 !default;

$ff-color-grey-lightest: #f7f9fc !default;
$ff-color-grey-mist: #f3f6fb !default;
$ff-color-grey-snow: #e4e8f0 !default;
$ff-color-grey-solitude: #dee2e7 !default;
$ff-color-grey-light: #9eacb4 !default;
$ff-color-grey-steel: #a5acbb !default;
$ff-color-grey-slate: #505b73 !default;

$ff-color-orange: #f59231 !default;
$ff-color-orange-dark: #f09316 !default;
$ff-color-orange-light: #fcf2e1 !default;

$ff-color-purple-light: #ac77ad !default;
$ff-color-purple: #87156f !default;
$ff-color-purple-dark: #471587 !default;

$ff-color-red-light: #df3232 !default;
$ff-color-red-dark: #df1c3c !default;
$ff-color-red: #d4351c !default;
$ff-color-red-coca-cola: #f40009 !default;
$ff-color-red-rejected: #e80e36 !default;
$ff-color-red-error-secondary: #e1c2bd !default;

$ff-color-silver: #878c8d !default;
$ff-color-silver-gradient: linear-gradient(
    87.43deg,
    #d6d2d0 3.53%,
    #f1efec 36.82%,
    #e2e2e2 65.17%,
    #c2c2c2 97.18%
) !default;
$ff-color-silver-gradient-darkened: linear-gradient(
    87.43deg,
    #a39f9d 3.53%,
    #bebcb9 36.82%,
    #afafaf 65.17%,
    #8f8f8f 97.18%
) !default;

$ff-color-white: #fff !default;
$ff-color-white-transparent-20: rgba(255, 255, 255, 0.2) !default;
$ff-color-white-transparent-50: rgba(255, 255, 255, 0.5) !default;

$ff-color-yellow: #fed130 !default;
$ff-colors: (
    'azure': $ff-color-azure,
    'blue-lighter': $ff-color-blue-lighter,
    'blue-light': $ff-color-blue-light,
    'blue': $ff-color-blue,
    'blue-corp': $ff-color-blue-corp,
    'blue-corp-dark': $ff-color-blue-corp-dark,
    'blue-highlight': $ff-color-blue-highlight,
    'blue-dark': $ff-color-blue-dark,
    'blue-dark-tint': $ff-color-blue-dark-tint,
    'blue-cinema': $ff-color-blue-cinema,
    'black': $ff-color-black,
    'bronze': $ff-color-bronze,
    'gold': $ff-color-gold,
    'green-lighter': $ff-color-green-lighter,
    'green-light': $ff-color-green-light,
    'green': $ff-color-green,
    'green-dark': $ff-color-green-dark,
    'grey-lightest': $ff-color-grey-lightest,
    'grey-mist': $ff-color-grey-mist,
    'grey-snow': $ff-color-grey-snow,
    'grey-light': $ff-color-grey-light,
    'grey-steel': $ff-color-grey-steel,
    'grey-slate': $ff-color-grey-slate,
    'orange': $ff-color-orange,
    'purple-light': $ff-color-purple-light,
    'purple': $ff-color-purple,
    'purple-dark': $ff-color-purple-dark,
    'red-light': $ff-color-red-light,
    'red': $ff-color-red,
    'transparent': transparent,
    'silver': $ff-color-silver,
    'white': $ff-color-white,
    'yellow': $ff-color-yellow,
);

$ff-color-success: #d0dfd8;
$ff-color-progress: #cedde5;

// ------ MARGINS & PADDINGS ------
$ff-spacing-1: 0.25rem;
$ff-spacing-2: 0.5rem;
$ff-spacing-3: 1rem;
$ff-spacing-4: 1.5rem;
$ff-spacing-5: 2rem;
$ff-spacing-6: 3rem;
$ff-spacing-7: 4rem;
$ff-spacing-8: 6rem;

$ff-spacing-sizes: (
    '0': 0,
    '4': $ff-spacing-1,
    '8': $ff-spacing-2,
    '16': $ff-spacing-3,
    '24': $ff-spacing-4,
    '32': $ff-spacing-5,
    '48': $ff-spacing-6,
    '64': $ff-spacing-7,
    '96': $ff-spacing-8,
);

$ff-header-bar-height: 70px;

// ------ BORDER RADIUSES ------
$ff-border-radius-sm: 0.375rem;
$ff-border-radius-md: 0.5rem;
$ff-border-radius-lg: 0.75rem;
$ff-border-radius: $ff-border-radius-md;

//------- Positioning ----------
/* Higher number equals higher level positioning of the given element. 
For example z-index: 10 will show on top of z-index: 5 */
$ff-z-index-1: -1;
$ff-z-index-2: 0;
$ff-z-index-3: 1;
$ff-z-index-4: 5;
$ff-z-index-5: 10;
$ff-z-index-6: 20;
$ff-z-index-7: 50;
$ff-z-index-8: 100;
$ff-z-index-9: 200;
$ff-z-index-10: 1000;
$ff-z-index-11: 1010;
$ff-z-index-12: 1020;
$ff-z-index-13: 1030;
$ff-z-index-14: 1040;
$ff-z-index-15: 1050;
$ff-z-index-16: 1060;

$ff-z-index-header-nav: $ff-z-index-13;
$ff-z-index-header-menu: $ff-z-index-12;
$ff-z-index-header-dropdown: $ff-z-index-1;
$ff-z-index-modal-overlay: $ff-z-index-14;
$ff-z-index-modal-overlay-background: $ff-z-index-15;
$ff-z-index-modal-overlay-content: $ff-z-index-16;
/* Usage for body element: $ff-z-index-body-element: $ff-z-index-4; */

// ------ BOOTSTRAP OVERRIDES ------
$enable-rounded: false;

$grid-size-small: 100%;
$grid-size-large: 1400px;
$grid-size-extra-large: 1920px;

$grid-breakpoints: (
    xs: 0,
    xsm: 385px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
) !default;

/* Buttons global variables */
$ff-button-sm-size: 45px;
$ff-button-md-size: 55px;
$ff-border-radius-circle: 50%;
$ff-border-radius-normal: 30px;
$ff-border-button-width: 2px;
$ff-border-button-type: solid;
$ff-border-button-type-primary: transparent;
$ff-border-button-color-secondary: $ff-color-grey-snow;
$ff-border-button-color-tertiary: $ff-color-white;
$ff-border-button-color-primary: $ff-color-blue-lighter;
$ff-border-button-primary: $ff-border-button-width $ff-border-button-type $ff-border-button-type-primary;
$ff-border-button-primary-reversed: $ff-border-button-width $ff-border-button-type $ff-border-button-color-primary;
$ff-border-button-secondary: $ff-border-button-width $ff-border-button-type $ff-border-button-color-secondary;
$ff-border-button-tertiary: $ff-border-button-width $ff-border-button-type $ff-border-button-color-tertiary;

$ff-border-button: (
    'primary': $ff-border-button-primary,
    'secondary': $ff-border-button-secondary,
    'tertiary': $ff-border-button-tertiary,
    'primary-reversed': $ff-border-button-primary-reversed,
);

/* Accreditation form */
$accreditation-form-field-width: 335px;
$accreditation-field-edited-green: #27ae5d !default;

$headless-header-bar-height: 4.375rem;

$fixture-results-container-max-width: 1350px;

$header-bar-height: 2.5rem;
$header-menu-bar-height: 3.5rem;
$mobile-bottom-nav-height: 72px;
