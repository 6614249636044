@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.linkLayout {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;

    .icon {
        width: 18px;
        height: 18px;
        stroke: $ff-color-blue-dark;

        &.active {
            stroke: $ff-color-blue-light;
        }
    }

    &:hover {
        .menuItem {
            cursor: pointer;
            color: $ff-color-blue-light;
        }

        .icon {
            stroke: $ff-color-blue-light;
        }
    }

    > button {
        border: none;
        background-color: transparent;
        height: 100%;
        padding: 0;
    }

    border-bottom: 2px solid transparent;
    &.active {
        border-bottom: 2px solid $ff-color-blue-lighter-dark;
    }
}

.menuItem {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $ff-color-blue-dark;
    @include font(
        $ff-font-size-8,
        $ff-line-height-120,
        $ff-font-family-open-sans-condensed,
        map-get($ff-spacing-sizes, '0'),
        false
    );
    font-size: 14px;
    cursor: default;

    &.active {
        color: $ff-color-blue-light;
    }

    &:active,
    &:focus {
        box-shadow: none;
    }
}

.subMenu {
    position: absolute;
    top: $header-menu-bar-height;
    left: 0;
    background-color: $ff-color-white;
    width: 320px;
    height: auto;
    display: flex;
    box-shadow: 0px 25px 40px rgba(29, 42, 69, 0.08);
    overflow-y: auto;
}
