@import '@/styles/abstracts/variables';

.fixedHeader {
    position: fixed;
    width: 100%;
    z-index: $ff-z-index-16;
    top: 0;
}

.icon {
    width: 24px;
    height: 24px;
}
