@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/theme-variables';

$mobile-header-bar-height: 55px;

.root {
    position: fixed;
    left: 0;
    right: 0;
    z-index: $ff-z-index-15;
    border-top: 1px solid $ff-color-grey-steel;
    border-bottom: 1px solid $ff-color-grey-steel;
    top: $mobile-header-bar-height;
    height: calc(100dvh - $mobile-header-bar-height - $mobile-bottom-nav-height);

    @include breakpoint(lg, up) {
        display: none;
    }
}

.menuCommon {
    background-color: $ff-color-blue-night;
    position: absolute;
    z-index: $ff-z-index-16;
    height: 100%;
    overflow-y: auto;
}

.menu {
    @extend .menuCommon;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
}

.mobileMenu {
    margin-bottom: auto;
}

.logoutIcon {
    margin-inline-end: 3px;
    path {
        fill: $ff-color-white;
    }
}
