// Sets the font size in px and rem
// sizeValue is in rem
@mixin set-font-size($sizeValue) {
    font-size: ($sizeValue * 16) * 1px; //fallback for old browsers
    font-size: $sizeValue * 1rem;
}

// Sets the line height in decimal and percentage
// heightValue is in decimal
@mixin set-line-height($heightValue) {
    line-height: $heightValue; //fallback for old browsers
    line-height: ($heightValue * 100) + unquote('%');
}

//Combines set-font-size and set-line-height
@mixin set-font-and-line($sizeValue, $heightValue) {
    @include set-font-size($sizeValue);
    @include set-line-height($heightValue);
}

// ------ FONTS ------
@mixin add-font($font, $src, $font-weight, $font-style, $fallback) {
    @font-face {
        font-family: $font;
        @if ($fallback != null) {
            src: local($font), $src, $fallback;
            font-display: fallback;
        } @else {
            src: $src;
        }
        font-weight: $font-weight;
        font-style: $font-style;
    }
}

//Combines set-font-size and set-line-height
@mixin font(
    // default set to body paragraph
    $sizeValueArray,
    $heightValue,
    $font-family,
    $margin-bottom: null,
    $uppercase: false,
    $font-weight: null
) {
    // set default size array
    @if ($sizeValueArray == null) {
        $sizeValueArray: $ff-font-p-array;
    }
    // set default height value
    @if ($heightValue == null) {
        $heightValue: $ff-line-height-100;
    }
    // set default font-family
    @if ($font-family == null) {
        $font-family: $ff-font-family-open-sans-regular;
    }
    //set default margin-bottom
    @if ($margin-bottom == null) {
        $margin-bottom: map-get(
            $map: $ff-spacing-sizes,
            $key: '32',
        );
    }
    @each $sizeName, $sizeValue in $sizeValueArray {
        @each $breakName, $breakValue in $grid-breakpoints {
            @if $sizeName == $breakName {
                @if $breakName != 'xs' {
                    @include breakpoint($breakValue, up) {
                        @include set-font-size($sizeValue);
                    }
                } @else {
                    @include set-font-size($sizeValue);
                }
            }
        }
    }
    line-height: $heightValue; //fallback for old browsers
    line-height: ($heightValue * 100) + unquote('%');
    font-family: $font-family;
    font-weight: $font-weight;
    margin-bottom: $margin-bottom;
    @if $uppercase {
        text-transform: uppercase;
    } @else {
        text-transform: none;
    }
}

@mixin removeUpperSpaceFromFirstLine($lineHeightMap) {
    @each $screenValue, $lineHeight in $lineHeightMap {
        @include breakpoint($screenValue, up) {
            &::before {
                content: '';
                display: block;
                height: 0;
                width: 0;
                margin-top: calc((1 - #{$lineHeight}) * 0.5em);
            }
        }
    }
}

@mixin font-with-variable-line-height(
    // default set to body paragraph
    $sizeValueArray,
    $lineHeightArray,
    $font-family,
    $margin-bottom: null,
    $uppercase: false
) {
    // set default size array
    @if ($sizeValueArray == null) {
        $sizeValueArray: $ff-font-p-array;
    }
    // set default height value
    @if ($lineHeightArray == null) {
        $heightValue: $ff-line-heights-array;
    }
    // set default font-family
    @if ($font-family == null) {
        $font-family: $ff-font-family-open-sans-regular;
    }
    //set default margin-bottom
    @if ($margin-bottom == null) {
        $margin-bottom: map-get(
            $map: $ff-spacing-sizes,
            $key: '32',
        );
    }
    @each $sizeName, $sizeValue in $sizeValueArray {
        @each $breakName, $breakValue in $grid-breakpoints {
            @if $sizeName == $breakName {
                @if $breakName != 'xs' {
                    @include breakpoint($breakValue, up) {
                        @include set-font-size($sizeValue);
                    }
                } @else {
                    @include set-font-size($sizeValue);
                }
            }
        }
    }
    @each $heightName, $heightValue in $lineHeightArray {
        @each $breakName, $breakValue in $grid-breakpoints {
            @if $heightName == $breakName {
                @if $breakName != 'xs' {
                    @include breakpoint($breakValue, up) {
                        line-height: $heightValue; //fallback for old browsers
                        line-height: ($heightValue * 100) + unquote('%');
                    }
                } @else {
                    line-height: $heightValue; //fallback for old browsers
                    line-height: ($heightValue * 100) + unquote('%');
                }
            }
        }
    }

    font-family: $font-family;
    margin-bottom: $margin-bottom;
    @if $uppercase {
        text-transform: uppercase;
    } @else {
        text-transform: none;
    }
}

// ------ BREAKPOINTS ------
@mixin breakpoint($breakpoint, $direction) {
    @if map-has-key($grid-breakpoints, $breakpoint) {
        // Get breakpoint value.
        $breakpoint-value: map-get($grid-breakpoints, $breakpoint);

        @if $direction == down {
            @media (max-width: ($breakpoint-value - 1)) {
                @content;
            }
        } @else if $direction == up {
            @media (min-width: $breakpoint-value) {
                @content;
            }
        } @else {
            @media ($direction: $breakpoint-value) {
                @content;
            }
        }

        // If the breakpoint doesn't exist.
    } @else {
        @if $direction == down {
            @media (max-width: $breakpoint) {
                @content;
            }
        } @else if $direction == up {
            @media (min-width: $breakpoint) {
                @content;
            }
        } @else {
            @media ($direction: $breakpoint) {
                @content;
            }
        }
    }
}

// ------ TRANSITIONS ------
/*usage: @include transition(background-color 1s 2s, color 2s);

output:
    -moz-transition: background-color 1s 2s, color 2s;
    -webkit-transition: background-color 1s 2s, color 2s;
    -ms-transition: background-color 1s 2s, color 2s;
    -o-transition: background-color 1s 2s, color 2s;
    transition: background-color 1s 2s, color 2s;
*/
@mixin transition($props...) {
    -webkit-transition: $props;
    -moz-transition: $props;
    -ms-transition: $props;
    -o-transition: $props;
    transition: $props;
}

// ------ TRANSFORMS ------
/*usage: @include transform(translateX(-50%) translateY(-50%) rotate(45deg));

output:
-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
-moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
-ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
-o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
transform: translateX(-50%) translateY(-50%) rotate(45deg);
*/
@mixin transform($prop) {
    -webkit-transform: $prop;
    -moz-transform: $prop;
    -ms-transform: $prop;
    -o-transform: $prop;
    transform: $prop;
}

// ------ KEYFRAMES ------
/*usage:
@include keyframes(bgcolor) {
  0% {
    background-color: #ffccf2;
  }
  50% {
    background-color: #ccffcc;
  }
  100% {
    background-color: #ccffff;
  }
};

output:
@-webkit-keyframes bgcolor { 0% { background-color: #ffccf2; }
  50% { background-color: #ccffcc; }
  100% { background-color: #ccffff; } }

@-moz-keyframes bgcolor { 0% { background-color: #ffccf2; }
  50% { background-color: #ccffcc; }
  100% { background-color: #ccffff; } }

@-ms-keyframes bgcolor { 0% { background-color: #ffccf2; }
  50% { background-color: #ccffcc; }
  100% { background-color: #ccffff; } }

@keyframes bgcolor { 0% { background-color: #ffccf2; }
  50% { background-color: #ccffcc; }
  100% { background-color: #ccffff; } }
*/
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

// ------ KEYFRAMES ------
/*usage:
@include animation(10s, 5s, changecolour);

output:
    -webkit-animation-delay: 10s;
    -webkit-animation-duration: 5s;
    -webkit-animation-name: changecolour;
    -webkit-animation-fill-mode: forward;
    -webkit-animation-direction: forwards;

    -moz-animation-delay: 10s;
    -moz-animation-duration: 5s;
    -moz-animation-name: changecolour;
    -moz-animation-fill-mode: forward;
    -moz-animation-direction: forwards;

    animation-delay: 10s;
    animation-duration: 5s;
    animation-name: changecolour;
    animation-fill-mode: forward;
    animation-direction: $direction;

*/
@mixin animation($delay, $duration, $animation, $direction: forward, $fillmode: forwards) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: $fillmode;
    -webkit-animation-direction: $direction;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: $fillmode;
    -moz-animation-direction: $direction;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: $fillmode;
    animation-direction: $direction;
}

// ------ VERTICAL CENTER ------
/*usage: @include vertical-center();

output:
   top: 50%;
   -ms-transform: translateY(-50%);
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
*/
@mixin vertical-center {
    top: 50%;
    transform: translateY(-50%);
}

// ------ ABSOLUTE POSITIONING ------
/*usage: @include absolute-position(100px, 100px, auto, auto);

output:
   position: absolute;
   top: 100px;
   right: 100px;
   bottom: auto;
   left: auto;
*/
@mixin absolute-position($top, $right, $bottom, $left) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

// ------ TEXT SHORTENING ------
/*usage: @include text-shorten();

output:
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
*/
@mixin text-shorten($whiteSpace: nowrap) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: $whiteSpace;
}

// ------ FADE TEXT ------
/*usage: @include fade(bottom);

output:
-webkit-mask-image: linear-gradient(180deg, #fff 80%, transparent);
mask-image: linear-gradient(180deg, #fff 80%, transparent);
*/
@mixin fade($direction) {
    @if $direction == 'left' {
        mask-image: linear-gradient(270deg, #fff 80%, transparent);
    }
    @if $direction == 'right' {
        mask-image: linear-gradient(90deg, #fff 80%, transparent);
    }
    @if $direction == 'bottom' {
        mask-image: linear-gradient(180deg, #fff 80%, transparent);
    }
}

// ------ HOVER-FOCUS ------
/*usage:
@include hover-focus() {
    color: $ff-color-white;
}

output:
&:hover,
&:focus,
&:active,
&:hover:active {
    color: $ff-color-white;
}
*/
@mixin hover-focus() {
    &:hover,
    &:focus,
    &:active,
    &:hover:active {
        @content;
    }
}

// ------ RTL UTILITIES ------
@mixin rtl() {
    [dir='rtl'] {
        @content;
    }
}

@mixin ltr() {
    [dir='ltr'] {
        @content;
    }
}

@mixin float-inline-end() {
    @include ltr() {
        float: right;
    }
    @include rtl() {
        float: left;
    }
}

@mixin inset-inline-start($value) {
    @include ltr() {
        left: $value;
    }
    @include rtl() {
        right: $value;
    }
}
@mixin inset-inline-end($value) {
    @include ltr() {
        right: $value;
    }
    @include rtl() {
        left: $value;
    }
}

@mixin padding-inline-start($value) {
    @include ltr() {
        padding-left: $value;
    }
    @include rtl() {
        padding-right: $value;
    }
}
@mixin padding-inline-end($value) {
    @include ltr() {
        padding-right: $value;
    }
    @include rtl() {
        padding-left: $value;
    }
}

@mixin margin-inline-start($value) {
    @include ltr() {
        margin-left: $value;
    }
    @include rtl() {
        margin-right: $value;
    }
}
@mixin margin-inline-end($value) {
    @include ltr() {
        margin-right: $value;
    }
    @include rtl() {
        margin-left: $value;
    }
}

// ------ MISC ------
@mixin line-overflow($line-clamp: 1, $overflow: ellipsis) {
    display: -webkit-box;
    -webkit-line-clamp: $line-clamp;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

@mixin flex-box($jf: null, $ai: null, $fd: null) {
    display: flex;
    justify-content: $jf;
    align-items: $ai;
    flex-direction: $fd;
}

@mixin border($color, $size: 1px, $type: solid) {
    border: $size $type $color;
}

@mixin object-fit-position($fit: null, $position: null) {
    object-fit: $fit;
    object-position: $position;
}

@mixin skeleton-animation($primary-color, $secondary-color) {
    position: absolute;
    height: 500%;
    width: 500%;
    transform: translateX(-100%);
    background-color: $primary-color;
    background-image: linear-gradient(90deg, $primary-color 0%, $secondary-color 50%, $primary-color 100%);
    content: '';
    animation: shimmer 2.5s linear infinite;

    @include keyframes(shimmer) {
        100% {
            transform: translateX(100%);
        }
    }
}
