$theme-color-body-background: var(--theme-color-body-background);
$theme-color-body-text: var(--theme-color-body-text);

$theme-color-header-background: var(--theme-color-header-background);
$theme-color-header-text: var(--theme-color-header-text);
$theme-color-header-link: var(--theme-color-header-link);
$theme-header-dropdown: var(--theme-header-dropdown);

$theme-color-link-hover: var(--theme-color-link-hover);
$theme-color-link-inactive: var(--theme-color-link-inactive);
$theme-color-link: var(--theme-color-link);
$theme-color-link-header: var(--theme-color-link-header);

$theme-color-selector-arrow: var(--theme-color-selector-arrow);
$theme-color-globe-hover: var(--theme-color-globe-hover);
$theme-color-active-text: var(--theme-color-active-text);

$theme-color-a-element: var(--theme-color-a-element);
$theme-color-a-element-hover: var(--theme-color-a-element-hover);
$theme-color-a-element-border-focus: var(--theme-color-a-element-border-focus);

$theme-color-box-shadow: var(--theme-color-box-shadow);
$theme-color-dropdown-button-hover: var(--theme-color-dropdown-button-hover);
$theme-color-dropdown-border: var(--theme-color-dropdown-border);
$theme-check-mark-color: var(--theme-check-mark-color);
$theme-dropdown-options-chosen-color: var(--theme-dropdown-chosen-option-color);
$theme-dropdown-options-base-color: var(--theme-dropdown-options-base-color);

$theme-divider-color: var(--theme-divider-color);

$theme-color-tournament-primary-color: var(--theme-color-tournament-primary-color);
$theme-color-tournament-secondary-color: var(--theme-color-tournament-secondary-color);

$theme-brackets-connector-color: var(--theme-brackets-connector-color);
$theme-brackets-background-color: var(--theme-brackets-background-color);
$theme-brackets-rounds-title-color: var(--theme-brackets-rounds-title-color);
$theme-match-card-background-color: var(--theme-match-card-background-color);
$theme-match-card-border-color: var(--theme-match-card-border-color);

$theme-accordion-background: var(--theme-accordion-background);
$theme-accordion-element-divider: var(--theme-accordion-element-divider);

$theme-match-result-background-color: var(--theme-match-result-background-color);
$theme-match-result-text-color: var(--theme-match-result-text-color);
$theme-match-result-background-color-live: var(--theme-match-result-background-color-live);
$theme-match-result-text-color-live: var(--theme-match-result-text-color-live);

$theme-tournament-timeline-background: var(--theme-tournament-timeline-background);
$theme-tournament-timeline-card-background: var(--theme-tournament-timeline-card-background);
$theme-tournament-timeline-card-hover: var(--theme-tournament-timeline-card-hover);
$theme-tournament-timeline-text: var(--theme-tournament-timeline-text);
$theme-tournament-timeline-text-second: var(--theme-tournament-timeline-text-second);

$theme-color-primary-background: var(--theme-color-primary-background);
$theme-color-primary-text: var(--theme-color-primary-text);
$theme-color-primary-highlight: var(--theme-color-primary-highlight);

$theme-stats-team-text: var(--theme-stats-team-text);
$theme-stats-team-text-second: var(--theme-stats-team-text-second);
$theme-stats-team-text-third: var(--theme-stats-team-text-third);
$theme-stats-team-background: var(--theme-stats-team-background);
$theme-stats-team-background-second: var(--theme-stats-team-background-second);

$theme-color-article-card-background: var(--theme-color-article-card-background);
$theme-color-round-status: var(--theme-color-round-status);

$theme-match-result-board-background: var(--theme-match-result-board-background);
$theme-match-result-board-text: var(--theme-match-result-board-text);
$theme-match-page-item-divider: var(--theme-match-page-item-divider);
$theme-match-page-background: var(--theme-match-page-background);
$theme-match-event-background: var(--theme-match-event-background);
$theme-whistle-event-background: var(--theme-whistle-event-background);
$theme-goal-event-background: var(--theme-goal-event-background);
$theme-goal-event-text: var(--theme-goal-event-text);
$theme-lineups-team-background: var(--theme-lineups-team-background);
$theme-lineups-players-background: var(--theme-lineups-players-background);
$theme-lineups-number-of-background: var(--theme-lineups-number-of-background);
$theme-lineups-number-of-text: var(--theme-lineups-number-of-text);

$theme-color-highlight: var(--theme-color-highlight);

$theme-video-controls-active-color: var(--theme-video-controls-active-color);
$theme-video-controls-inactive-color: var(--theme-video-controls-inactive-color);
