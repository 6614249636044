@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/theme-variables';
@import '@/styles/abstracts/mixins';

$dropdownItemColor: $ff-color-blue-light;
$iconColor: $ff-color-grey-steel;
$activeColor: $ff-color-blue-lighter;

.dropdown {
    border: none !important;
    width: fit-content !important;
    border-radius: 4px !important;
    border: 2px solid $ff-color-grey-snow !important;
    background-color: $ff-color-white;
    color: $ff-color-blue-cinema;

    &:focus,
    &:active,
    &:focus-within {
        box-shadow: 0 0 0 2px $theme-color-a-element-border-focus !important;
    }

    &:hover,
    &:active,
    &:focus {
        border-color: $ff-color-blue-lighter !important;

        .dropdownButton {
            .dropdownButton__icon {
                svg {
                    stroke: none;
                    path {
                        fill: $activeColor;
                    }
                }
            }
        }
    }

    .dropdownButton {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 3px;
        @include set-font-size($ff-font-size-9);
        .dropdownButton__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            svg {
                max-height: 16px;
                max-width: 21px;
                stroke: none;
                rect,
                path {
                    fill: $iconColor;
                }
            }
        }

        .dropdownButton__label {
            color: $ff-color-blue-cinema;
        }

        .dropdownButton__selected,
        .dropdownButton__selected__mobile {
            font-family: $ff-font-family-open-sans-bold;
        }
        .dropdownButton__selected__mobile {
            display: none;
            text-transform: capitalize;
        }
        @include breakpoint(lg, down) {
            .dropdownButton__selected,
            .dropdownButton__label {
                display: none;
            }
            .dropdownButton__selected__mobile {
                display: block;
            }
        }
    }

    .item {
        display: flex;
        p:first-child {
            flex: 0 0 auto;
        }
        text-overflow: ellipsis;
        color: $ff-color-blue-cinema;
        position: relative;

        .itemPrefix {
            color: $ff-color-grey-slate;
            white-space: pre;
        }

        &:hover {
            background-color: $ff-color-grey-lightest;
            color: $activeColor;
            .itemPrefix {
                color: $activeColor;
            }
        }

        &.selected {
            svg {
                path {
                    fill: $activeColor;
                }
            }
        }

        p {
            @include set-font-size($ff-font-size-9);
            font-weight: $ff-font-weight-semibold;
        }
    }

    [class*='DropDown'] {
        border-color: $ff-color-grey-snow;
        box-shadow: 0px 25px 40px rgba(29, 42, 69, 0.1);
        padding-top: calc($ff-spacing-1 + $ff-spacing-2);
        padding-bottom: calc($ff-spacing-1 + $ff-spacing-2);
        min-width: 159px;
        width: max-content;

        max-width: 700px;
        @include breakpoint(md, down) {
            max-width: 500px;
        }
        @include breakpoint(sm, down) {
            max-width: 400px;
        }

        right: 0;
        left: unset;
    }
}

[dir='rtl'] {
    .dropdown {
        [class*='DropDown'] {
            left: 0;
            right: unset;
        }
    }
}

.dropdownWrapper {
    width: 100%;
}

.flexReverse {
    flex-direction: row-reverse;
}
