@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/theme-variables';

.button__atom {
    border-radius: $ff-border-radius-normal;
    font-family: $ff-font-family-open-sans-bold;
    outline: none;
    line-height: $ff-line-height-160;
    cursor: pointer;
    &:active,
    &:focus {
        box-shadow: 0px 0px 0px $ff-border-button-width $theme-color-a-element-border-focus;
    }
}

.button__atom__link {
    @extend .button__atom;
    @include flex-box(center, center, row);
}

.button__primary {
    background-color: $ff-color-blue-lighter;
    color: $ff-color-white !important;
    border: map-get($ff-border-button, 'primary');
    &:hover {
        background-color: $ff-color-blue-dark-tint;
    }
}

.button__primary__reversed {
    background-color: $ff-color-white;
    color: $ff-color-blue-lighter;
    border: map-get($ff-border-button, 'primary-reversed');
    &:hover,
    &:focus,
    &:active {
        color: $ff-color-blue-dark-tint;
        border-color: $ff-color-blue-dark-tint;
    }
}

.button__secondary {
    background-color: $ff-color-white;
    color: $ff-color-blue-cinema;
    border: map-get($ff-border-button, 'secondary');
    &:hover {
        background-color: $ff-color-grey-snow;
    }
}

.button__tertiary {
    background-color: transparent;
    color: $ff-color-white !important;
    border: map-get($ff-border-button, 'tertiary');
    &:hover {
        background-color: rgba($ff-color-blue-corp, 0.75);
    }
}

.button__transparent {
    background-color: transparent;
    color: $ff-color-blue-light !important;
    border-color: transparent;
}

.button__pure__text {
    background-color: transparent;
    border: none !important;
    color: $ff-color-blue-cinema !important;
    border: map-get($ff-border-button, 'tertiary');
    border-radius: $ff-border-radius-sm;
}

.button__sm {
    @include set-font-size($ff-font-size-8);
    padding: map-get($ff-spacing-sizes, '4') map-get($ff-spacing-sizes, '16');
}

.button__md {
    @include set-font-size($ff-font-size-8);
    padding: map-get($ff-spacing-sizes, '8') map-get($ff-spacing-sizes, '32');
}

.button__lg {
    @include set-font-size($ff-font-size-8);
    padding: map-get($ff-spacing-sizes, '16') map-get($ff-spacing-sizes, '48');
}

.button__disabled {
    cursor: none;
    pointer-events: none;
    &.button__primary {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), $ff-color-blue-light;
    }
    &.button__primary__reversed {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), $ff-color-blue-light;
    }
    &.button__secondary {
        border-color: $ff-color-grey-steel;
        color: $ff-color-grey-steel;
    }
    &.button__tertiary {
        border-color: $ff-color-grey-steel;
        color: $ff-color-grey-steel;
    }
    &.button__pure__text {
        color: $ff-color-grey-slate;
    }
}

.button__loading {
    cursor: none;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        display: none;
    }
    &::before {
        content: '. .';
        animation: rotate 0.7s linear infinite;
        margin-right: $ff-spacing-2;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
