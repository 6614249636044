@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: $ff-z-index-modal-overlay-content;
    height: $mobile-bottom-nav-height;
    background: $ff-color-blue-night;
    overflow: hidden;

    > div {
        position: relative;
        height: 100%;
        gap: $ff-spacing-6;
        &::after {
            @include skeleton-animation($ff-color-blue-night, $ff-color-blue-logo);
        }
    }
}

.circle {
    height: 18px;
    width: 100%;
    border-radius: $ff-border-radius-md;

    position: relative;
    overflow: hidden;
    background: $ff-color-grey-lightest;
    z-index: 1;

    &::after {
        @include skeleton-animation($ff-color-grey-lightest, $ff-color-grey-steel);
    }
}
