@import '@/styles/abstracts/variables';

@each $color, $value in $ff-colors {
    .ff-bg-#{$color} {
        background-color: $value;
    }
    .ff-text-#{$color} {
        color: $value;
    }
}

.ff-cursor-pointer {
    cursor: pointer;
}

.fp-list-style-none {
    list-style: none;
}
