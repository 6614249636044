@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.root {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: $ff-z-index-modal-overlay-content;
    background-color: $ff-color-blue-night;
    height: 72px;
    padding: 0 $ff-spacing-3;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media print {
        display: none;
    }
}
