@import 'react-date-range/dist/styles.css'; // main style file
@import 'react-date-range/dist/theme/default.css'; // theme css file

/* ------- CONTAINER ------- */

.rdrDateRangePickerWrapper {
    display: inline-block;
    width: 100%;
    height: 100%;
    @include breakpoint(md, 'up') {
        display: inline-flex;
        height: auto;
    }
}

/* ------- LEFT SIDE ------- */

.rdrDefinedRangesWrapper {
    padding-top: $ff-spacing-7;
    z-index: $ff-z-index-5;
    width: 100%;
    @include breakpoint(md, 'up') {
        padding-top: $ff-spacing-4;
        width: 226px;
    }
}

.rdrStaticRangeLabel {
    color: $ff-color-blue-lighter;
    @include font(
        (
            xs: $ff-font-size-8,
        ),
        $ff-line-height-120,
        $ff-font-family-open-sans-regular,
        null,
        false
    );
    margin-bottom: 0;
}

.rdrStaticRanges {
    width: 28em;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(md, 'up') {
        width: auto;
        margin-left: 0;
        margin-right: 0;
    }
    @include breakpoint(md, 'down') {
        border: none;
    }
}

.rdrStaticRange {
    border-left: 8px solid transparent;
    &:hover {
        border-left: 8px solid $ff-color-grey-lightest;
    }
}

.rdrStaticRangeSelected {
    background: $ff-color-grey-lightest;
    color: $ff-color-blue-lighter;
    @include font(
        (
            xs: $ff-font-size-8,
        ),
        $ff-line-height-120,
        $ff-font-family-open-sans-regular,
        null,
        false
    );
    margin-bottom: 0;
    box-sizing: border-box;
    border-left: 8px solid $ff-color-blue-lighter;
    &:hover {
        border-left: 8px solid $ff-color-blue-lighter;
    }
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
    background: $ff-color-grey-lightest;
}

/* ------- RIGHT SIDE ------- */

.rdrCalendarWrapper {
    width: 100%;
    height: 100%;
    padding-left: $ff-spacing-1;
    padding-bottom: $ff-spacing-6;
    padding-right: $ff-spacing-1;
    padding-top: $ff-spacing-1;

    @include breakpoint(md, 'up') {
        height: auto;
        padding-left: $ff-spacing-4;
        padding-bottom: $ff-spacing-6;
        padding-right: $ff-spacing-4;
        padding-top: $ff-spacing-6;
    }
}

/* ------- TOP DATE INPUT FIELDS ------- */

.rdrDateDisplayItem + .rdrDateDisplayItem {
    margin-left: 0.833em;
}

.rdrDateDisplayWrapper {
    background: $ff-color-white;
    width: 28em;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(md, 'up') {
        width: auto;
        margin-left: 0;
        margin-right: 0;
    }
}

.rdrDateDisplayItem {
    border-radius: $ff-border-radius-sm;
    border: 1px solid $ff-color-grey-steel;
}

.rdrDateDisplayItem,
.rdrDateDisplayItemActive {
    input {
        color: $ff-color-blue-cinema;
        height: 60px;
        @include font($ff-font-p-small-array, $ff-line-height-120, $ff-font-family-open-sans-regular, null, false);
        margin-bottom: 0;
    }
}

.rdrDateDisplayItemActive {
    border: 1px solid $ff-color-blue-lighter;
}

.rdrStaticRange {
    border-bottom: none;
    font-weight: $ff-font-weight-bold;
}

.rdrDateDisplayItem + .rdrDateDisplayItem {
    margin-left: $ff-spacing-5;
}

/* ------- DROPDOWNS ------- */

.rdrMonthPicker,
.rdrYearPicker {
    select {
        @include font(
            (
                xs: $ff-font-size-8,
            ),
            $ff-line-height-120,
            $ff-font-family-open-sans-regular,
            null,
            false
        );
        font-weight: $ff-font-weight-semibold;
        margin-bottom: 0;
        text-transform: capitalize;
        option {
            text-align: left;
        }
    }
}

/* ------- ARROWS ------- */

.rdrMonthAndYearWrapper {
    width: 28em;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(md, 'up') {
        width: auto;
        margin-left: 0;
        margin-right: 0;
    }
}

.rdrNextPrevButton {
    background: $ff-color-white;
}

/* ------- CALENDAR ------- */

.rdrMonth {
    width: 28em;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(md, 'up') {
        width: 35.667em;
    }
}

// SET HEIGHT OF DAYS

.rdrDay {
    height: 4em;
    line-height: 4em;
    width: calc(100% / 7);
    @include breakpoint(md, 'up') {
        height: 5em;
        line-height: 5em;
        width: calc(100% / 7);
    }
}

.rdrDay span {
    height: 4em;
    width: 100%;
    @include breakpoint(md, 'up') {
        height: 5em;
        width: 100%;
    }
}

.rdrStartEdge,
.rdrEndEdge {
    // MAKE DAYS A CIRCLE, REMOVE ADJUSTMENTS AND SET COLOR
    border-radius: $ff-border-radius-circle !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    color: $ff-color-blue-lighter !important;
}

.rdrDay .rdrDayNumber {
    // SET THE NUMBER OF THE DAY TO ONLY BE 2EM IN HEIGHT
    height: 2em !important;
    margin-top: auto;
    margin-bottom: auto;
}

.rdrDayHovered .rdrDayNumber {
    // IF WE HOVER, THEN MAKE IT FULL HEIGHT
    height: 4em !important;
    @include breakpoint(md, 'up') {
        height: 5em !important;
    }
}

/* ------- SET CONNECTIONS DEPENDING ON SITUATION ------- */

.rdrDayHovered {
    [class='rdrDayEndPreview'] {
        // IF WE HOVER AND HAVE SELECTED START DATE ALREADY
        background: $ff-color-grey-snow;
        height: 2em !important;
        margin-top: auto;
        margin-bottom: auto;
        top: 3px;
        z-index: 1;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        & + .rdrDayNumber {
            z-index: 2;
        }
    }

    [class='rdrDayStartPreview rdrDayEndPreview'] {
        // IF WE HOVER AND HAVE NOT SELECTED START
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }
}

/* ------- REMOVE SMALL ADJUSTMENTS FROM PRE-DEFINED THEME ------- */

.rdrDayNumber {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* ------- SET HEIGHT  ------- */

.rdrInRange {
    height: 2em !important;
    margin-top: auto;
    margin-bottom: auto;
    color: $ff-color-blue-lighter !important;
}

.rdrDayStartPreview,
.rdrDayEndPreview {
    top: 0px;
    color: $ff-color-blue-lighter !important;
}

/* ------- MAKE SELECTION AS CIRCLES ON HOVER ------- */

.rdrDayEndPreview {
    border-radius: $ff-border-radius-circle !important;
}

.rdrDayStartPreview {
    border-radius: $ff-border-radius-circle !important;
}

/* ------- MAKE RANGE SMALLER IN HEIGHT AND SET IN MIDDLE & BACKGROUND ------- */

.rdrDayInPreview + .rdrDayNumber {
    height: 2em !important;
    margin-top: auto;
    margin-bottom: auto;
    background: $ff-color-grey-snow;
}

/* ------- SET HOVER BACKGROUND AND SHAPE ------- */
/* ------- WARNING: DO NOT CHANGE ORDER! ------- */

.rdrDayStartPreview ~ .rdrDayNumber {
    // Preview start (Hover on date or static ranges) height and color
    background: $ff-color-grey-snow;
    border-radius: $ff-border-radius-circle !important;
    height: 4em !important;
    @include breakpoint(md, 'up') {
        height: 5em !important;
    }
}

.rdrDayEndPreview ~ .rdrDayNumber {
    // Preview end (Hover on date or static ranges) height and color
    background: $ff-color-grey-snow;
    border-radius: $ff-border-radius-circle !important;
    height: 4em !important;
    @include breakpoint(md, 'up') {
        height: 5em !important;
    }
}

.rdrStartEdge ~ .rdrDayNumber {
    // Connection between selected start and range
    background: $ff-color-blue-lighter !important;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.rdrDayHovered .rdrDayNumber {
    // Hover color
    background: $ff-color-grey-snow;
    border-radius: $ff-border-radius-circle !important;
}

.rdrEndEdge ~ .rdrDayNumber {
    // Connection between selected end and range
    background: $ff-color-blue-lighter;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.rdrDayHovered .rdrEndEdge ~ .rdrDayNumber span {
    background: $ff-color-blue-lighter;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.rdrDayHovered .rdrStartEdge ~ .rdrDayNumber span {
    background: $ff-color-blue-lighter;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.rdrDayPassive > .rdrStartEdge ~ .rdrDayNumber {
    // Remove background color on passive days (Days outside selected month
    background: none !important;
}

.rdrDayPassive > .rdrEndEdge ~ .rdrDayNumber {
    // Remove background color on passive days (Days outside selected month
    background: none !important;
}

/* ------- SET TEXT COLOR IN CALENDAR ------- */

.rdrDayNumber {
    span {
        color: $ff-color-grey-slate;
        @include font(
            (
                xs: $ff-font-size-7,
            ),
            $ff-line-height-120,
            $ff-font-family-open-sans-regular,
            null,
            false
        );
        margin-bottom: 0;
        height: auto;
    }
}

/* ------- SET STYLING FOR TODAY ------- */

.rdrDayToday {
    // NOT HOVERED AND NOT SELECTED
    > .rdrDayNumber {
        border-radius: $ff-border-radius-circle;
        border: 1px solid $ff-color-black;
        > span:after {
            content: none;
        }
        height: 4em !important;
        @include breakpoint(md, 'up') {
            height: 5em !important;
        }
    }
}

.rdrDayToday {
    // NOT HOVERED BUT SELECTED
    > .rdrEndEdge ~ .rdrDayNumber {
        height: 2em !important;
        border-radius: 0;
        border: none;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        > span:after {
            content: none;
        }
    }
    > .rdrStartEdge ~ .rdrDayNumber {
        height: 2em !important;
        border-radius: 0;
        border: none;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        > span:after {
            content: none;
        }
    }
    > .rdrStartEdge.rdrEndEdge ~ .rdrDayNumber {
        height: 2em !important;
        border-radius: 0;
        border: none;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        > span:after {
            content: none;
        }
    }
}

.rdrDayToday.rdrDayHovered {
    // HOVERED
    > .rdrEndEdge ~ .rdrDayNumber {
        background: $ff-color-blue-lighter;
        border-radius: 0;
        border: none;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        > span:after {
            content: none;
        }
        height: 4em !important;
        @include breakpoint(md, 'up') {
            height: 5em !important;
        }
    }
}

.rdrDayDisabled {
    background: none;
}

/* ------- REMOVE ALL THEME-DEFINED BORDER AND BORDER RADIUS ------- */

.rdrDayStartPreview,
.rdrDayEndPreview {
    border: none;
}

.rdrDayInPreview {
    border: none;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
    border-radius: 0;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
    border-radius: 0;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
    border-radius: 0;
}

.rdrSelected {
    left: 0;
    top: 0;
}
