@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.ffFigure {
    height: 100%;
    width: 100%;
    .figure {
        display: table;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
    }

    .picture {
        display: flex;
        width: 100%;
        max-width: 100%;
        height: 100%;
        .img {
            width: 100%;
            max-width: 100%;
            height: 100%;
            align-self: flex-start;
        }
    }
}

.verticalImage {
    @include object-fit-position(cover, 50% 40%);
}

.horizontalImage {
    @include object-fit-position(cover, 50% 30%);
}
