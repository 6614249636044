@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.wrapper {
    margin-bottom: $mobile-bottom-nav-height;

    @include breakpoint(lg, up) {
        margin-bottom: 0;
    }
}

.rectangle,
.rectangle2,
.rectangle3,
.square,
.square2 {
    border-radius: $ff-border-radius-md;

    position: relative;
    overflow: hidden;
    background: $ff-color-grey-steel;

    &::after {
        @include skeleton-animation($ff-color-grey-steel, $ff-color-grey-lightest);
    }
}

.rectangle {
    width: 160px;
    height: 35px;
}

.rectangle2 {
    width: 100%;
    max-width: 305px;
    height: 130px;
}

.rectangle3 {
    width: 160px;
    height: 52px;
}

.square,
.square2 {
    height: 311px;
}

.grid {
    display: grid;
    gap: $ff-spacing-3;
    grid-template-columns: 1;
    grid-template-rows: repeat(3, 1fr);

    @include breakpoint(md, up) {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, 1fr);

        .square {
            grid-column: span 3;
        }

        .square2 {
            grid-column: span 2;
        }
    }
}

.grid2 {
    display: grid;
    gap: $ff-spacing-3;
    grid-template-columns: 1;
    grid-template-rows: repeat(4, 1fr);

    .square {
        height: 120px;
    }

    @include breakpoint(md, up) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: $ff-spacing-5;
    }
}
