@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.linkLayout {
    .linkStyle {
        display: flex;
        padding: $ff-spacing-3;
        padding-left: $ff-spacing-5;

        background-color: $ff-color-grey-lightest;
        text-decoration: none;
        text-transform: none;
        color: $ff-color-grey-slate;
        @include font(
            (
                xs: $ff-font-size-9,
            ),
            $ff-line-height-160,
            $ff-font-family-open-sans-regular,
            map-get($ff-spacing-sizes, '0'),
            false
        );
        font-size: 14px;
        white-space: pre-wrap;
        &:hover {
            background-color: $ff-color-grey-snow;

            color: $ff-color-blue-light;
        }

        &:active,
        &:focus {
            box-shadow: none;
        }
    }
}

.subHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: $ff-spacing-3 $ff-spacing-4;

    &.open {
        background-color: $ff-color-grey-solitude;
        font-weight: $ff-font-weight-semibold;
    }

    &:hover {
        background-color: $ff-color-grey-snow;

        .link {
            color: $ff-color-blue-light;
        }
    }
}

.menuItem {
    color: $ff-color-blue-cinema;
    @include font($ff-font-overline-array, $ff-line-height-100, $ff-font-family-open-sans-regular, null, null);
    font-size: 14px;
    white-space: pre-wrap;

    &.link {
        cursor: pointer;
        &:hover {
            color: $ff-color-blue-light;
        }
    }
}

.icon {
    width: 18px;
    height: 18px;
    stroke: $ff-color-blue-night;
    transform: rotate(180deg);

    &.open {
        transform: rotate(0);
    }
}
