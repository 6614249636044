@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/theme-variables';
@import '@/styles/abstracts/mixins';

.checkboxAreaContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.label {
    font-size: 14px;
    line-height: 22.4px;
    font-weight: $ff-font-weight-semibold;
    color: $ff-color-grey-slate;
    margin-left: $ff-spacing-3;
}

.container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 24px;
    height: 24px;
}

.container:hover .checkmark {
    border-color: $ff-color-blue-lighter;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    border: 1px solid $ff-color-grey-steel;
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $ff-color-white;
}

.container input:checked ~ .checkmark {
    background-color: $ff-color-blue-lighter;
    border-color: $ff-color-blue-lighter;
}

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmark:after {
    display: block;
}

.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
