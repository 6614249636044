@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.fullWidthSection {
    width: 100%;
    height: 480px;
    background: $ff-color-blue-night;
    position: relative;
    overflow: hidden;

    .wrapper {
        height: 100%;
        &::after {
            @include skeleton-animation($ff-color-blue-night, $ff-color-blue-logo);
        }

        gap: $ff-spacing-4;
    }
}

.rectangle,
.rectangle2 {
    position: absolute;
    border-radius: $ff-border-radius-md;

    position: relative;
    overflow: hidden;
    background: $ff-color-grey-lightest;
    z-index: 1;

    &::after {
        @include skeleton-animation($ff-color-grey-lightest, $ff-color-grey-steel);
    }
}

.rectangle {
    width: 218px;
    height: 42px;
}

.rectangle2 {
    width: 100%;
    max-width: 496px;
    height: 129px;
}

.button {
    border-radius: $ff-border-radius-normal;
    width: 143px;
    height: 48px;

    position: relative;
    overflow: hidden;
    background: $ff-color-blue-logo;
    z-index: 1;

    &::after {
        @include skeleton-animation($ff-color-blue-logo, $ff-color-blue-lighter);
    }
}
