@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.container {
    position: absolute;
    z-index: $ff-z-index-3;
}

.iconColor {
    svg {
        width: 18px;
        height: 18px;
        path {
            stroke: $ff-color-blue-light;
            stroke-width: 3;
        }
    }
}

.closeWrapper {
    position: relative;
}

.close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    > svg {
        path {
            fill: $ff-color-grey-lightest;
        }
    }
}

@include breakpoint(md, down) {
    .close {
        right: 15px;
    }
}
