@import '@/styles/abstracts/functions';
@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

$ff-image-overlay-color-30: rgba(0, 15, 44, 0.3);
$ff-image-overlay-color-50: rgba(0, 15, 44, 0.5);

.figureImage {
    height: 100%;
    width: 100%;

    figure {
        width: 100%;
        height: 100%;

        .figcaption {
            font-family: $ff-font-family-open-sans-italic;
            color: $ff-color-grey-slate;
        }
    }
}

.img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;

    &.ffImage {
        height: 100%;
    }
}

.verticalImage {
    @include object-fit-position(cover, 50% 40%);
}

.horizontalImage {
    @include object-fit-position(cover, 50% 30%);
}
