@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.subMenuWrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    border-bottom: 2px solid $ff-color-blue-lighter-dark;
}

.titleLayout {
    display: none;
}

.linkLayout {
    white-space: nowrap;
    box-sizing: border-box;
    flex-shrink: 0;
}
