@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/theme-variables';

$live-match-country-padding: 1.25rem;
$live-match-country-name-margin-top: 0.313rem;
$live-match-score-time-margin-left-right: 1rem;

.flag {
    width: 52px;
}

.liveScores {
    display: grid;
    grid-template-columns: minmax(91px, 1fr) 75px minmax(91px, 1fr);

    @media only screen and (max-device-width: 480px) {
        grid-template-columns: 75px 75px 75px;
        column-gap: $live-match-score-time-margin-left-right;
    }

    .country {
        @include flex-box(center, center, column);

        padding-left: $live-match-country-padding;
        padding-right: $live-match-country-padding;

        .name {
            margin-top: $live-match-country-name-margin-top;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            font-family: $ff-font-family-open-sans-regular;
            text-transform: uppercase;

            @media only screen and (max-device-width: 480px) {
                display: flex;
                flex-wrap: nowrap;
                text-align: center;
            }
        }
    }
    .scoreAndTime {
        @include flex-box(center, center, column);

        .score {
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            font-family: $ff-font-family-open-sans-condensed;
            margin-top: 0.125rem;
            margin-bottom: 0.125rem;
        }

        .time {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            font-family: $ff-font-family-open-sans-regular;
            margin-top: $ff-spacing-1;
        }
    }
}

@include breakpoint(md, up) {
    .liveScores {
        margin-right: 6%;
    }
}

@include breakpoint(xl, up) {
    .liveScores {
        margin-right: 12%;
    }
}
