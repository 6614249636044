@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.container {
    background-color: $ff-color-blue-night;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: scroll;

    .innerContainer {
        max-width: 500px;
        margin: auto;
        display: flex;
        flex-direction: column;
        color: $ff-color-white;
        padding: $ff-spacing-5;

        .overline {
            @include set-font-size($ff-font-size-11);
            font-weight: $ff-font-weight-semibold;
            text-transform: unset;
            margin-bottom: 2dvh;
        }

        .headline {
            text-transform: unset;
            margin-bottom: 3dvh;
        }

        .slider {
            margin-top: 1dvh;
            padding-bottom: 1dvh;
        }

        .actions {
            width: 100%;
            gap: $ff-spacing-2;

            .visitSiteBtn {
                margin-left: auto;
                width: 174px;

                @include breakpoint(xsm, down) {
                    @include set-font-size($ff-font-size-9);
                }
            }
        }
    }
}

.instructionStep {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 74dvh;
    padding-bottom: 4dvh;

    .instructionImage {
        height: 51dvh;
        background-size: cover;
        margin-top: auto;
    }
}

.carouselControl {
    width: 3rem;
    height: 3rem;
    background: none;
    outline: none;
    border-color: $ff-color-white;
    border-radius: $ff-border-radius-circle;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
        opacity: 0.6;
    }

    &.disabled {
        opacity: 0.4;
    }

    svg {
        width: 18px;
        height: 18px;

        path {
            stroke: $ff-color-white;
        }
    }
}
