@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/theme-variables';
@import '@/styles/abstracts/functions';

$padding-row: 80px;
$padding-top: 98px;
$padding-bottom: 72px;

.headlessContainer {
    height: 100vh;
    overflow: hidden;

    main {
        height: 100%;
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
        }
    }
}

.bodyBackgroundContainer {
    background-size: cover;
    height: 100%;
}

.mobileMargin {
    margin-top: 1.5rem !important;
}

.headlessBodyContainerMargins {
    width: 1760px;
    height: 840px;
    margin: $padding-top $padding-row $padding-bottom $padding-row;
}

.header {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $ff-color-blue-cinema;
    height: $headless-header-bar-height;
    border-bottom: 3px solid $ff-color-white;

    .logoContainer {
        position: absolute;
        right: $padding-row;
        top: 0;
        background-color: white;
        height: pxToRem(128);
        width: pxToRem(128);
        padding: 1em;
        border-radius: 0 0 6px 6px;
        img {
            object-fit: contain;
        }
    }
    .clockContainer {
        position: absolute;
        left: $padding-row;
        top: 50%;
        transform: translateY(-50%);
        .clock {
            font-family: $ff-font-family-open-sans-condensed;
            font-weight: $ff-font-weight-bold;
            font-size: pxToRem(38);
            line-height: $ff-line-height-120;
            color: $ff-color-white;
        }
    }
}

.logo {
    picture {
        img {
            object-fit: contain;
        }
    }
}
