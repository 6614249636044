@import '@/styles/abstracts/variables';

:root {
    --theme-color-body-background: #{$ff-color-white};
    --theme-color-body-text: #{$ff-color-blue-cinema};
    --theme-color-header-background: #{$ff-color-white};
    --theme-color-header-text: #{$ff-color-blue-cinema};
    --theme-color-header-link: #{$ff-color-blue};

    --theme-color-link-hover: #{$ff-color-blue-light};
    --theme-color-link-inactive: #{$ff-color-grey-slate};
    --theme-color-link: #{$ff-color-white};
    --theme-color-link-header: #{$ff-color-blue-cinema};

    --theme-color-selector-arrow: #{$ff-color-black};
    --theme-color-globe-hover: #{$ff-color-blue};
    --theme-color-active-text: #{$ff-color-blue};

    --theme-color-a-element: #{$ff-color-blue-light};
    --theme-color-a-element-hover: #{$ff-color-blue-corp};
    --theme-color-a-element-border-focus: #{$ff-color-blue-highlight};
    --theme-color-a-element-border-non-focus: #{$ff-color-white};

    --theme-color-box-shadow: #{$ff-color-grey-steel};
    --theme-color-dropdown-button-hover: #{$ff-color-grey-mist};
    --theme-color-dropdown-border: #{$ff-color-grey-steel};
    --theme-check-mark-color: #{$ff-color-blue-light};
    --theme-dropdown-chosen-option-color: #{$ff-color-blue-light};
    --theme-dropdown-options-base-color: #{$ff-color-black};

    --theme-divider-color: #{$ff-color-grey-snow};

    --theme-brackets-connector-color: #{$ff-color-grey-steel};
    --theme-brackets-background-color: #{$ff-color-grey-lightest};
    --theme-brackets-rounds-title-color: #{$ff-color-grey-steel};

    --theme-match-card-background-color: #{$ff-color-white};
    --theme-match-card-border-color: #{$ff-color-grey-steel};

    --theme-accordion-background: #{$ff-color-white};
    --theme-accordion-element-divider: #{$ff-color-grey-snow};

    --theme-match-result-background-color: #{$ff-color-grey-snow};
    --theme-match-result-text-color: #{$ff-color-black};
    --theme-match-result-background-color-live: #{$ff-color-blue-lighter};
    --theme-match-result-text-color-live: #{$ff-color-white};

    --theme-tournament-timeline-background: #{$ff-color-white};
    --theme-tournament-timeline-card-background: #{$ff-color-white};
    --theme-tournament-timeline-card-hover: #{$ff-color-blue-light};
    --theme-tournament-timeline-text: #{$ff-color-blue-cinema};
    --theme-tournament-timeline-text-second: #{$ff-color-grey-slate};

    --theme-stats-team-text: #{$ff-color-blue-cinema};
    --theme-stats-team-text-second: #{$ff-color-grey-slate};
    --theme-stats-team-text-third: #{$ff-color-grey-slate};
    --theme-stats-team-background: #{$ff-color-white};
    --theme-stats-team-background-second: #{$ff-color-white};

    --theme-color-article-card-background: #{$ff-color-white};

    --theme-color-round-status: #{$ff-color-grey-slate};

    --theme-color-primary-background: #{$ff-color-grey-lightest};
    --theme-color-primary-text: #{$ff-color-blue-cinema};
    --theme-color-primary-highlight: #{$ff-color-blue};

    --theme-match-result-board-background: #{$ff-color-blue-cinema};
    --theme-match-result-board-text: #{$ff-color-white};
    --theme-match-page-item-divider: #{$ff-color-grey-slate};
    --theme-match-page-background: #{$ff-color-grey-lightest};
    --theme-match-event-background: #{$ff-color-white};
    --theme-whistle-event-background: #{$ff-color-grey-snow};
    --theme-goal-event-background: #{$ff-color-blue};
    --theme-goal-event-text: #{$ff-color-white};
    --theme-lineups-team-background: #{$ff-color-grey-snow};
    --theme-lineups-players-background: #{$ff-color-white};
    --theme-lineups-number-of-background: #{$ff-color-blue};
    --theme-lineups-number-of-text: #{$ff-color-white};

    --theme-color-highlight: #{$ff-color-blue};

    --theme-video-controls-active-color: #{$ff-color-blue-lighter};
    --theme-video-controls-inactive-color: #{$ff-color-white};
}
