@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/functions';

.event {
    display: grid;
    grid-template-columns: 150px 46px 150px;
    justify-items: center;
    align-items: center;
    grid-template-rows: 8px 8px 8px;
    font-family: $ff-font-family-open-sans-regular;
    font-size: 11px;
    color: white;

    .firstColumn {
        justify-self: end;
    }

    .thirdColumn {
        justify-self: start;
    }

    .element {
        @include flex-box(center, center, row);
        column-gap: 8px;
    }
}
