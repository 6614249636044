@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.container {
    background-color: $ff-color-blue-night;
    height: 100dvh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .innerContainer {
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 425px;
        padding: 5dvh 2.5rem 0;
    }

    .logo {
        width: 100%;
        height: auto;
    }

    .description {
        color: $ff-color-white;
        line-height: $ff-line-height-160;
        text-align: justify;
    }
    .background {
        background-image: url('../../icons/pwa-installation-bg-pink.png');
        background-size: contain;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        max-width: 600px;
        width: 100%;
    }
}
