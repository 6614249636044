@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.notificationBanner {
    padding: map-get($map: $ff-spacing-sizes, $key: '24') 0;
    padding-left: 1.3rem;
    top: 0;
    background-color: $ff-color-red-error-secondary;
    border-color: $ff-color-blue-cinema;
    color: $ff-color-blue-cinema;
    @include animation(0s, 0.4s, notif-in-top);

    a {
        color: $ff-color-white;
    }

    .message {
        @include font(
            $ff-font-p-small-array,
            $ff-line-height-130,
            $ff-font-family-open-sans-semibold,
            map-get($ff-spacing-sizes, '0'),
            false
        );

        @include breakpoint(lg, up) {
            font-family: $ff-font-family-open-sans-semibold;
        }
    }

    .translationUnavailableLink {
        text-decoration: underline;
    }

    .icon {
        transform: scale(0.75);
        cursor: pointer;

        path {
            fill: $ff-color-blue-dark;
        }
    }

    .notificationInnerDiv {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .iconContainer {
            height: fit-content;
        }
    }
}
.arrowIcon {
    margin-left: 7px;
    path {
        stroke: $ff-color-white;
        stroke-width: 4;
    }
}
[dir='rtl'] .arrowIcon {
    transform: rotate(180deg);
}

@include keyframes(notif-in-top) {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.clickable {
    cursor: pointer;
}
