@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/theme-variables';
@import '@/styles/abstracts/mixins';

$arrow-color: $theme-color-selector-arrow;
$bgColor: $theme-color-header-background;

.dropup {
    position: relative;
}

.noBorder {
    border: none !important;
}

.username {
    text-transform: capitalize;
}

.selected {
    margin-left: 1rem;
}
[dir='rtl'] .selected {
    margin-left: 0rem;
    margin-right: 1rem;
}

.pretext {
    align-self: center;
    @include font($ff-font-overline-array, $ff-line-height-160, null, map-get($ff-spacing-sizes, '0'), false);
}

.dropupButton {
    background-color: $ff-color-blue-cinema;
    color: $ff-color-white;
    border: solid 1px $ff-color-grey-steel;
    cursor: pointer;
    vertical-align: middle;
    text-align: right !important;
    padding: 0px;
    @include font($ff-font-overline-array, $ff-line-height-100, $ff-font-family-open-sans-semibold, null, null);

    &:focus,
    &:active {
        outline: none;
        box-shadow: 0px 0px 0px $ff-border-button-width $ff-color-white;
    }
}

.isDropup {
    bottom: 50px;
    right: 0px;
}

.isRightAligned {
    right: 0px;
}

.dropdownCommon {
    display: none;
    position: absolute;
    background-color: $ff-color-white;
    min-width: 150px;
    border: 1px solid $ff-color-white;
    border: 1px solid $ff-color-grey-snow;
    box-shadow: 0px 25px 40px 0px rgba(29, 42, 69, 0.08);
    z-index: $ff-z-index-3;
    width: 100%;
    overflow-y: auto;

    margin-top: 5px;
}

.dropdownContent {
    @extend .dropdownCommon;
    right: -24px;
    white-space: nowrap;
    width: 248px;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    border-bottom: 2px solid $ff-color-blue-lighter-dark;
}

details > summary {
    list-style: none;
    display: flex;
}
summary::-webkit-details-marker {
    display: none;
}

.summaryChevronUp,
.summaryChevronDown {
    display: flex;
    align-items: center;
}

.summaryChevronDown {
    display: none;
}

details[open] > summary > div.summaryChevronUp {
    display: none;
}

details[open] > summary > div.summaryChevronDown {
    display: block;
}

details > .summaryChevronUp {
    display: none;
}

.summaryContent {
    margin-left: 10px;
}

.dropdownContentRtl {
    @extend .dropdownCommon;
    left: -1rem;
}

.arrowContainer {
    height: 16px;
    svg {
        transition: 0.2s linear;
        stroke: $ff-color-white;
    }
}

.dropupContentButton {
    width: 100%;
    border: none;
    cursor: pointer;
    color: $ff-color-grey-slate;
    padding: 14px 16px;
    text-decoration: none;
    display: block;
    font-size: 14px;
    margin-bottom: 0px;
    border-radius: 0px;

    img {
        width: 2rem;
    }
}

.noBackground {
    background: unset;
}

.dropupContentButton:hover,
.dropdownAccordionItem:hover {
    background-color: $ff-color-grey-mist;
    color: $ff-color-blue-lighter;

    svg {
        path {
            stroke: $ff-color-blue-lighter;
        }
    }

    .logoutIcon {
        path {
            fill: $ff-color-blue-lighter;
        }
    }
}

.rotate {
    svg {
        transform: rotateX(-180deg);
    }
}

.floatLeft {
    height: 20px;
    float: left;
    svg > path {
        transition: 0.1s linear;
    }
}

.dropup:hover,
.dropupActive {
    .dropupButton {
        border-color: $ff-color-grey-slate;
    }
}

details[open].dropdownAccordionItem {
    background-color: $ff-color-grey-snow;
}

.icon {
    width: 24px;
    height: 24px;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(4px) brightness(50%);
    z-index: $ff-z-index-1;
    margin-top: 5px;
}
