@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/functions';

%text {
    @include font(
        (
            xs: 0.9,
        ),
        $ff-line-height-100,
        $ff-font-family-open-sans-regular,
        0,
        false
    );
}

.lastRow {
    border-top: 1px solid $ff-color-grey-snow;
    width: 100%;
    padding-top: 1rem;
}

.list {
    @include flex-box(center, center, row);
    flex-wrap: wrap;

    .listItem {
        @extend %text;
        color: $ff-color-grey-slate;
        border-right: 1px solid $ff-color-grey-steel;

        &:last-child {
            border: none;
        }
    }
}
.listItem {
    .downloadButton {
        button:first-child {
            height: fit-content;
            background-color: transparent;
            border: none;

            p {
                @extend %text;
                font-weight: $ff-font-weight-light;
            }

            svg {
                width: 14px;
                height: 14px;
            }

            &:hover,
            &:focus {
                p {
                    color: $ff-color-blue-lighter;
                    font-weight: $ff-font-weight-semibold;
                }
            }
        }

        > div {
            z-index: $ff-z-index-header-nav;
            min-width: 197px !important;
        }
    }
}
