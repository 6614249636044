@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.fullWidthSection {
    @include breakpoint(lg, down) {
        padding: 0;
    }
}

.rectangle,
.rectangle2 {
    width: 100%;
    height: 300px;

    position: relative;
    overflow: hidden;
    z-index: 1;

    @include breakpoint(lg, up) {
        height: 277px;
    }

    @include breakpoint(xl, up) {
        height: 384px;
    }
}

.rectangle {
    background: $ff-color-grey-lightest;

    &::after {
        @include skeleton-animation($ff-color-grey-lightest, $ff-color-grey-steel);
    }

    @include breakpoint(lg, up) {
        border-top-right-radius: $ff-border-radius-md;
        border-bottom-right-radius: $ff-border-radius-md;
        width: calc(100% * 5 / 12);
    }
}
.rectangle2 {
    background: $ff-color-blue-night;
    padding: 0 1.5rem 2rem;
    gap: $ff-spacing-3;

    &::after {
        @include skeleton-animation($ff-color-blue-night, $ff-color-blue-logo);
        bottom: 0;
    }

    @include breakpoint(lg, up) {
        border-top-left-radius: $ff-border-radius-md;
        border-bottom-left-radius: $ff-border-radius-md;
        width: calc(100% * 7 / 12);
    }
}

.title,
.title2,
.circle {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: $ff-border-radius-md;

    background: $ff-color-grey-lightest;

    &::after {
        @include skeleton-animation($ff-color-grey-lightest, $ff-color-grey-steel);
    }
}

.title {
    height: 32px;
    width: 90%;
    margin-top: $ff-spacing-7;
}

.title2 {
    height: 32px;
    width: 70%;
}

.button {
    border-radius: $ff-border-radius-normal;
    width: 100%;
    height: 40px;
    margin-top: auto;
    margin-bottom: $ff-spacing-2;

    position: relative;
    overflow: hidden;
    background: $ff-color-blue-logo;
    z-index: 1;

    &::after {
        @include skeleton-animation($ff-color-blue-logo, $ff-color-blue-lighter);
    }

    @include breakpoint(lg, up) {
        width: 106px;
        margin-top: unset;
    }
}

.circle {
    width: 48px;
    height: 48px;
    border-radius: $ff-border-radius-circle;

    margin: 0 3px;
}
