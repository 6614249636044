@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

@mixin border-inactive() {
    border-style: solid;
    border-color: $ff-color-grey-steel;
}

.searchBar,
.searchBarActive {
    position: relative;
    height: 60px;
    width: 100%;
    @include font($ff-font-search-input-array, $ff-line-height-150, $ff-font-family-open-sans-regular, null, null);
    @include breakpoint(md, up) {
        height: 80px;
    }

    .textField {
        padding: $ff-spacing-2;
        padding-inline-start: $ff-spacing-4;
        height: 100%;
        width: 100%;
        border: 1px solid $ff-color-grey-steel;
        border-radius: $ff-border-radius-sm 0 0 $ff-border-radius-sm;
        background-color: $ff-color-white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-width: 1px 0 1px 1px;
        @include border-inactive();

        &.searchActive {
            border-radius: $ff-border-radius-sm 0 0 0;
        }

        &:focus {
            outline: none;
            border-color: $ff-color-blue-lighter;
        }
    }

    .searchIconActiveWrapper {
        cursor: pointer;
        border-radius: 0 $ff-border-radius-sm $ff-border-radius-sm 0;
        border-width: 1px 1px 1px 0;
        @include border-inactive();

        &:disabled {
            cursor: default;
        }

        &.searchActive {
            border-radius: 0 $ff-border-radius-sm 0 0;
            border-color: $ff-color-blue-lighter;
        }

        &.borderActive {
            border-radius: 0 $ff-border-radius-sm $ff-border-radius-sm 0;
            border-color: $ff-color-blue-lighter;
        }
    }

    .searchIcon {
        path {
            fill: $ff-color-white;
        }
    }

    &:focus {
        .textField {
            outline: none;
            border-color: $ff-color-blue-lighter;
        }

        .searchIconActiveWrapper {
            border-color: $ff-color-blue-lighter;
        }
    }
}

.relative {
    position: relative;
}

.searchResultsContainer {
    z-index: 20;

    a {
        border-left: 1px solid $ff-color-blue-lighter;
        border-right: 1px solid $ff-color-blue-lighter;
    }

    a:last-child {
        border-left: 1px solid $ff-color-blue-lighter;
        border-right: 1px solid $ff-color-blue-lighter;
        border-bottom: 1px solid $ff-color-blue-lighter;
        border-radius: 0 0 $ff-border-radius-sm $ff-border-radius-sm;
    }
}

.searchItem {
    z-index: 21;
    padding-left: $ff-spacing-4;
    border-radius: 0;
    cursor: pointer;
    @include font($ff-font-search-input-array, $ff-line-height-150, $ff-font-family-open-sans-regular, null, null);
    &:hover {
        background-color: $ff-color-grey-lightest;
    }
}

.activeSearchItem {
    background-color: $ff-color-grey-lightest;
}

@include breakpoint(md, up) {
    .searchResultsContainer {
        position: absolute;
    }
}

@include rtl() {
    .searchBar,
    .searchBarActive {
        flex-direction: row-reverse;
    }

    .searchIconActiveWrapper {
        border-width: 1px 0 1px 1px;
    }

    .searchItem {
        padding-left: 0;
        padding-right: $ff-spacing-4;
    }
}

.fixtureResultSuggestion {
    position: absolute;
    max-height: 170px;
    min-width: 100%;
    overflow-y: scroll;
    border-left: 1px solid $ff-color-blue-lighter;
    border-right: 1px solid $ff-color-blue-lighter;
    border-bottom: 1px solid $ff-color-blue-lighter;
    border-radius: 0 0 $ff-border-radius-sm $ff-border-radius-sm;
    padding: 0;

    @include breakpoint(md, up) {
        max-height: 360px;
    }
}
