@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/theme-variables';
@import '@/styles/abstracts/mixins';

details > summary {
    list-style: none;
    display: flex;
}

summary::-webkit-details-marker {
    display: none;
}
.summaryChevron {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 20px;
    margin-inline-end: $ff-spacing-2;
    cursor: pointer;
}

details[open] > summary > div.summaryChevron {
    > svg {
        transform: rotate(90deg);
    }

    &.rotate180deg > svg {
        transform: rotate(180deg);
    }
}

.dropdownCommon {
    display: none;
    position: absolute;
    min-width: 150px;
    border: 1px solid $ff-color-grey-snow;
    box-shadow: 0px 25px 40px 0px rgba(29, 42, 69, 0.08);
    z-index: $ff-z-index-3;
    width: 100%;
    overflow-y: auto;

    margin-top: 21px;
    @include breakpoint(lg, up) {
        margin-top: 20px;
    }
}

.summaryContent {
    background-color: $ff-color-grey-lightest;
}

.dropdownContentRtl {
    @extend .dropdownCommon;
    left: -1rem;
}

.dropupContentButton {
    width: 100%;
    border: none;
    cursor: pointer;
    color: $ff-color-grey-slate;
    padding: 14px 16px;
    text-decoration: none;
    display: block;
    border-radius: 0px;
    font-size: 14px;

    &:hover {
        color: $ff-color-blue-lighter;
    }
}

.dropup:hover,
.dropupActive {
    .dropupButton {
        border-color: $ff-color-grey-slate;
    }
}

.chevron {
    path {
        stroke-width: 4;
    }
}
