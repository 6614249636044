@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.errorBanner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $ff-border-radius-sm;
    overflow: hidden;

    @include breakpoint(md, down) {
        min-width: 74vw;
    }
}
