@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.header {
    height: $header-menu-bar-height;
    background: $ff-color-blue-night;
    position: relative;
    overflow: hidden;

    > div {
        height: 100%;
        gap: $ff-spacing-3;
        &::after {
            @include skeleton-animation($ff-color-blue-night, $ff-color-blue-logo);
        }
    }

    @include breakpoint(lg, up) {
        height: $header-bar-height;
    }
}

.circle,
.rectangle,
.rectangle2 {
    height: 18px;
    width: 18px;
    border-radius: $ff-border-radius-md;

    position: relative;
    overflow: hidden;
    background: $ff-color-grey-lightest;
    z-index: 1;

    &::after {
        @include skeleton-animation($ff-color-grey-lightest, $ff-color-grey-steel);
    }
}

.rectangle {
    width: 160px;
}

.rectangle2 {
    width: 50px;
}

.subHeader {
    height: $header-menu-bar-height;

    position: relative;
    overflow: hidden;
    background: $ff-color-grey-steel;
    z-index: $ff-z-index-3;

    > div {
        height: 100%;
        gap: $ff-spacing-4;
        &::after {
            @include skeleton-animation($ff-color-grey-steel, $ff-color-grey-lightest);
        }
    }

    .rectangle {
        background: $ff-color-blue-night;
        z-index: $ff-z-index-3;
        margin-inline-end: 0;

        &::after {
            @include skeleton-animation($ff-color-blue-night, $ff-color-blue-logo);
        }
    }
}

.wrapper {
    gap: $ff-spacing-3;
    position: absolute;
    right: 0;

    &[dir='rtl'] {
        right: unset;
        left: 0;
    }
}

.containerWrapper {
    position: relative;
}
