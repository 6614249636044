.ff-container {
    width: 100%;
    max-width: $grid-size-large;
    margin-right: auto;
    margin-left: auto;
}

.ff-section {
    display: flex;
    flex-wrap: wrap;
}

.ff-col-1 {
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    flex: 0 0 auto;
    width: 100%;
}

.ff-col-2 {
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    flex: 0 0 auto;
    width: 50%;
}

.ff-col-3 {
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    flex: 0 0 auto;
    width: 33%;
}

.ff-col-4 {
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    flex: 0 0 auto;
    width: 25%;
}

@include breakpoint(sm, up) {
    .ff-col-sm-1 {
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        flex: 0 0 auto;
        width: 100%;
    }

    .ff-col-sm-2 {
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        flex: 0 0 auto;
        width: 50%;
    }

    .ff-col-sm-3 {
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        flex: 0 0 auto;
        width: 33%;
    }

    .ff-col-sm-4 {
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        flex: 0 0 auto;
        width: 25%;
    }
}

@include breakpoint(xxl, up) {
    .ff-col-xxl-1 {
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        flex: 0 0 auto;
        width: 100%;
    }

    .ff-col-xxl-2 {
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        flex: 0 0 auto;
        width: 50%;
    }

    .ff-col-xxl-3 {
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        flex: 0 0 auto;
        width: 33%;
    }

    .ff-col-xxl-4 {
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        flex: 0 0 auto;
        width: 25%;
    }
}

#__next {
    > div {
        display: none;
        background-color: $theme-color-body-background;
        color: $theme-color-body-text;
    }
}

.h-100 {
    height: 100%;
}
