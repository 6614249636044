@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.secondLevelTitle {
    cursor: pointer;
    color: $ff-color-white;
    margin-bottom: 0;
}

.icon {
    height: 24px;
    width: 24px;
    margin-inline-end: $ff-spacing-3;

    img {
        filter: invert(1) grayscale(1);
    }
}

.expandIcon {
    height: 18px;
    width: 18px;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}
