@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

$live-match-score-time-margin-left-right: 1rem;
$live-match-country-padding: 1.25rem;
$live-match-country-name-margin-top: 0.313rem;
$live-match-score-time-margin-left-right: 1rem;

.wrapper {
    box-shadow: 0px 25px 40px rgba(29, 42, 69, 0.08);
    margin-bottom: 20px;
}

.chevronIcon {
    stroke: $ff-color-blue-lighter;
    width: 20px;
    margin-bottom: 14px;
}

.isToggled .chevronIcon {
    transform: rotate(180deg);
    stroke: $ff-color-blue-dark-tint;
}

.filesDownloadButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 360px;
    border: 0px solid $ff-color-blue-lighter;
    background-color: #f7f9fc;
    color: $ff-color-blue-lighter;
    font-size: #{map-get($ff-font-sizes-array, '16')}rem;
    font-weight: $ff-font-weight-bold;
    p {
        margin: 0 8px 0 0;
    }

    &.isToggled {
        color: $ff-color-blue-dark-tint;
        border-color: $ff-color-blue-dark-tint;
    }
}

.topRow {
    @include flex-box(space-between, center, row);
    background-color: $ff-color-blue-lighter;
}

.viewMore {
    @include flex-box(null, center, row);

    span {
        @include flex-box(null, center, row);
        @include set-font-size($ff-font-size-9);
        font-weight: $ff-font-weight-semibold;
        color: $ff-color-white;

        svg {
            path {
                stroke: $ff-color-white;
            }
        }
    }
}

.middleRow {
    @include flex-box(center, center, column);

    .arrowButtons {
        @include flex-box(center, center, row);
        margin-top: 5%;
    }
}

.noMatchTodayText {
    text-transform: none;
}
