@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/functions';

.loadingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        animation: loading 0.8s infinite ease;
        width: 24px;
        height: 24px;
    }
}

@include keyframes(loading) {
    from {
        @include transform(rotate(0));
    }
    to {
        @include transform(rotate(360deg));
    }
}
