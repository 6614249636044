@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

.videoDownloadSection {
    position: relative;

    .filesDownloadButton {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 map-get($ff-spacing-sizes, '48');
        height: 48px;
        border-radius: 360px;
        border: 2px solid $ff-color-blue-lighter;
        background-color: $ff-color-white;
        color: $ff-color-blue-lighter;
        font-size: #{map-get($ff-font-sizes-array, '16')}rem;
        font-weight: $ff-font-weight-bold;
        p {
            margin: 0 8px 0 0;
        }

        &.isToggled {
            color: $ff-color-blue-dark-tint;
            border-color: $ff-color-blue-dark-tint;
        }
    }

    .buttonActive {
        opacity: 1;
    }

    .buttonDisabled {
        opacity: 0.3;
    }

    .downloadStartedBlock {
        margin: 0;
        position: relative;
        width: 100%;
        padding-right: 20% !important;
        color: $ff-color-blue-cinema;
        font-size: #{map-get($ff-font-sizes-array, '12')}rem;
        font-weight: $ff-font-weight-semibold;
        background-color: $ff-color-progress;

        svg {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-90%, -50%);

            [dir='rtl'] & {
                right: unset;
                left: 0;
                transform: translate(90%, -50%);
            }

            cursor: pointer;
        }
    }

    .downloadStartedBlock.downloadFinishedBlock {
        background-color: $ff-color-success;
    }

    .downloadStartedBlock.downloadErrorBlock {
        background-color: $ff-color-red-error-secondary;
    }

    .dropdown {
        width: 100%;
        padding: map-get($ff-spacing-sizes, '16');
        margin-top: 10px;
        position: var(--dropdownPosition, unset);
        @include breakpoint(lg, up) {
            position: absolute;
            z-index: $ff-z-index-3;
        }
        background-color: $ff-color-white;
        border: 1px solid $ff-color-grey-snow;
        border-radius: 4px;
        box-shadow: 0px 25px 40px rgba(29, 42, 69, 0.08);

        .groupTitle {
            font-size: 16px;
            color: $ff-color-blue-cinema;
            text-transform: none;
            font-weight: normal;
            font-style: normal;
            margin: map-get($ff-spacing-sizes, '8') 0;
        }

        .downloadTitle {
            font-size: 13px;
            color: $ff-color-blue-cinema;
            margin-bottom: map-get($ff-spacing-sizes, '4');
        }

        .selectAllButton {
            font-size: 13px;
            cursor: pointer;
        }

        .downloadSize {
            font-size: 10px;
            color: $ff-color-grey-slate;
            margin-top: 5px;
        }

        .downloadOption {
            margin-bottom: map-get($ff-spacing-sizes, '8');
        }

        .downloadButton {
            width: 100%;
            text-align: center;
        }

        .termsAndConditions {
            font-size: 10px;
            font-weight: $ff-font-weight-semibold;
            font-style: normal;
            line-height: 150%;

            a {
                font-size: 10px;
                font-weight: $ff-font-weight-semibold;
                font-style: normal;
            }
        }

        .divider {
            border: none;
            border-top: 1px solid $ff-color-grey-snow;
            margin-left: -#{map-get($ff-spacing-sizes, '16')};
            margin-right: -#{map-get($ff-spacing-sizes, '16')};
            margin-top: map-get($ff-spacing-sizes, '16');
            margin-bottom: map-get($ff-spacing-sizes, '16');
        }
    }

    .chevronIcon {
        stroke: $ff-color-blue-lighter;
        width: 20px;
    }

    .isToggled .chevronIcon {
        transform: rotate(180deg);
        stroke: $ff-color-blue-dark-tint;
    }

    .checkbox {
        padding: 0;
        width: 24px;
        height: 24px;
        color: $ff-color-blue-lighter;
        border-radius: 4px;

        overflow: hidden;
        vertical-align: middle;
        border: 1px solid $ff-color-grey-steel;

        &:checked {
            border: 1px solid $ff-color-blue-lighter;
        }
    }

    .downloadProgressBar {
        transition: width 0.5s ease;
        position: absolute;
        top: 0;
        left: 0;
        height: 3px;
        background-color: $ff-color-blue-lighter;
    }

    .downloadProgressBarOuter {
        position: relative;
        width: 100%;
        height: 3px;
        background-color: $ff-color-grey-snow;
    }
}
